import * as React from 'react';

import {useEffect, useState} from "react";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { grey } from '@mui/material/colors';

import ChoseQuestion from './chose_question.js';
import ChoseHotseatUser from './chose_hotseat_user.js';
import questions from './questions.js';

import fire from '../images/fire.gif';
import crown from '../images/crown.png';
import crown_bison from '../images/crown_bison-modified.png';

import Circle from './circle2.js';

import useColorChange from 'use-color-change';

import './style.css';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

const color = grey[500];

// TODO: control the view based on database values, if someone comes in late do something?
export default function GameContainer(props) {
  const [category, setCategory] = useState("");
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [uopen, setUOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleUClose = () => setUOpen(false);
  const [question, setQuestion] = useState("");
  const [hotseatUser, setHotseatUser] = useState("");
  const [askingUser, setAskingUser] = useState("");
  const [wipe, setWipe] = useState(false);

  const colorStyle = useColorChange(question, {
      higher: "orange",
      lower: "orange",
      duration: 10000
    });

    const colorStyle2 = useColorChange(category, {
        higher: "orange",
        lower: "orange",
        duration: 10000
      });

  // Set state
  // quesiton, category, hotseat, asking user
  let disabled = true;
  if (hotseatUser == props.user_id) {
    disabled = false;
  }


  // Get State
  useEffect(() => {
    const stateref = ref(database, 'games/' + props.game_id +"/state/");
    onValue(stateref, (snapshot) => {
      const data = snapshot.val();
      setCategory(data.category);
      setCategoryChoices(data.categoryChoices);
      setHotseatUser(data.hotseatU);
      setAskingUser(data.askingUser);
      setQuestion(data.question);
      if (data.askingUser == props.user_id) {
          setOpen(true);
      }
    });
    return () => off(stateref)
  }, []);


  const changeHotseat = () => {
    setUOpen(true);
  }

  const setHotseatU = (u) => {
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      hotseatU: u,
      question: question,
      category: category,
      askingUser: askingUser,
      categoryChoices: categoryChoices,
    });
  }

  const setQ = (q, cat) => {
    set(ref(database, '/games/' + props.game_id +  "/state/"), {
      hotseatU: hotseatUser,
      question: q,
      category: cat,
      askingUser: askingUser,
      categoryChoices: categoryChoices,
    });
  }

  const click = () => {
    let three_categories = getThreeCategories(questions);
    // set asking user as well
    let au = pickRandomUser(props.users, hotseatUser);
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      hotseatU: hotseatUser,
      question: question,
      category: category,
      askingUser: au,
      categoryChoices: three_categories,
    });
  }

// TODO: only show to the user who can chose, pull the categories/etc from the central database
 let circle;
 if (props.users) {
   circle = <Circle users={props.users} hotseatUser={hotseatUser} askingUser={askingUser}/>
 }


  return (
      <div id="bounce1">
      <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Box sx={{mt:4, ml:2, mr: 2}}>
              <Typography variant="h5">
                #{props.game_id} <br/>
                {props.user_id} <br />
              </Typography>
              <br/>
              <br/>
              <br/>
              <br/>

              <Typography variant="subtitle" align="center">
              The person in the hotseat can move you to the next question <br/>
                <br/><Button onClick={click} disabled={disabled} variant="contained">Next Question</Button>
              </Typography>
            </Box>
            </Grid>
          <Grid item xs={12} md={10}>
      <Box sx={{mt:3, ml:2}}>
      <Grid container spacing={2}>
      <Grid item xs={12} md={9}>

      <Typography variant="h5" align="center">
        <b>Topic</b><br/> <div style={colorStyle}>{category}</div>
      </Typography>
      <Typography variant="h5" align="center">
        <b>Question</b>
      </Typography>
      <Typography variant="h2" align="center">
        <div style={colorStyle}>{question}</div>
      </Typography>
      <br/>
      <br/>
      <br/>

        {circle}

          <ChoseQuestion categoryChoices={categoryChoices} setQuestion={setQ} open={open} questions={questions} handleClose={handleClose}/>
          <ChoseHotseatUser users={props.users} setUser={setHotseatU} open={uopen} handleClose={handleUClose}/>

        </Grid>
        <Grid item xs={12} md={3}>
        <Box sx={{display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'}}>
          <img src={crown_bison} width="200"/>
          <br/>
          <br/>
          <b>hotseat</b> {hotseatUser} <br/><img src={fire} width="50" />

          <br/> <br/>
          <b>asking </b>{askingUser}<br/> <img src={crown} width="30" />
          <br/> <br/>
          <Typography variant="h6" align="center">

            <Button onClick={changeHotseat}>Put someone in the hotseat</Button>
          </Typography>

        </Box>
        </Grid>

        </Grid>
        </Box>
        </Grid>
        </Grid>
      </div>
  );
}

function getThreeCategories(questions) {
    return shuffle(Object.keys(questions)).slice(0, 3);
};

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


function pickRandomUser(users, hotseatU) {
  // pick a random user but not the hotseat user
  let remainingArr = users.filter(name => name != hotseatU);
  return shuffle(remainingArr)[0];
}
