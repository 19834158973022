import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import database from './firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

//const theme = createTheme();

export default function LobbyPlayers(props) {
  const [players, setPlayers] = useState();

  useEffect(() => {
    const uref = ref(database, 'games/' + props.game_id +"/users/");
    onValue(uref, (snapshot) => {
      const data = snapshot.val();
      const players =  Object.keys(data).map((user, step) => {
              return (
                  <p key={user}>
                    {user}
                  </p>
                )
            });
      setPlayers(players);
      props.setUsers(Object.keys(data));
    });

    return () => off(uref)
  }, []);

  return (
    <div>
    {players}
    </div>
  );

}
