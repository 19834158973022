import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

//TODO: elect a lead user to calculate from

// function calculate WINNER
function calculate_winner(game_id) {
  // get user list
  const uref = ref(database, 'games/' + game_id +"/users/");
  onValue(uref, (snapshot) => {
    const data = snapshot.val();
    const keys = Object.keys(data);
    let high_score = 0;
    let winner;
    for (let step = 0; step < keys.length; step++) {
      let user = data[keys[step]];
      if (user.score > high_score) {
        high_score = user.score;
        winner = keys[step];
      }
    }
    set(ref(database, '/games/' + game_id + '/winner/'), {
      username: winner,
    });
  });
  // for each user, check score

}
// TODO: what happens if two people tie?

function calculate_best_of(game_id, rounds, users) {
  const dataref = ref(database, 'games/' + game_id + "/roundquestions/");
  onValue(dataref, (snapshot) => {
    const data = snapshot.val();
    calculate_most_contentious(game_id, rounds, users, data);
    calculate_easiest_and_hardest(game_id, rounds, users, data);
  });
}

// function calculate most Contentious (most different answers)
// smallest "most guessed" person
// tiebreaker longest time to guess
function calculate_easiest_and_hardest(game_id, rounds, users, data) {
  const keys = Object.keys(data);
  if (keys.length != rounds) {
    console.log("SOMETHING WRONG");
  }
  let most_right = 0;
  let least_right = 100;
  let most_time_left = 0; // TODO: factor in time
  let winner;
  let hardest;

  // Iterate through rounds
  for (let step = 1; step < keys.length +1; step++) {
    const k = Object.keys(data[step]);

    // Iterate through submissions
    for (let s2 = 0; s2 < k.length; s2++) {
      let u = k[s2];
      let correct = 0;

      // Iterate through guesses
      for (let s3 = 0; s3 < users.length; s3++) {
        let user = users[s3];
        const ks = data[step][u][user];
        if (ks) {
          if (ks.correct) {
            correct++;
          }

        }
      }
      if (correct > most_right) { //TODO: deal with ==
        most_right = correct;
        winner = data[step][u];
        //most_time_left =
      }
      if (correct < least_right) {
        least_right = correct;
        hardest = data[step][u];
      }
    }
  }
  let high_percentage = (most_right / users.length)*100; // TODO: should be length - 1
  let least_percentage = (least_right / users.length)*100; // TODO: should be length - 1
  set(ref(database, '/games/' + game_id + '/easiest/'), {
    question: winner.question,
    type: winner.type,
    url: winner.url,
    percent: high_percentage,
  }); // TODO: add time
  set(ref(database, '/games/' + game_id + '/hardest/'), {
    question: hardest.question,
    type: hardest.type,
    url: hardest.url,
    percent: least_percentage,
  }); // TODO: add time
}

// // calculate most easily guessed
// // most right, shortest time to guess is tiebreak
// function calculate_hardest(game_id, rounds, users, data) {
//   const keys = Object.keys(data);
//   if (keys.length != rounds) {
//     console.log("SOMETHING WRONG");
//   }
//   let least_right = 0;
//   let least_time_left = 0; // TODO: factor in time
//   let winner;
//
//   // Iterate through rounds
//   for (let step = 1; step < keys.length +1; step++) {
//     const k = Object.keys(data[step]);
//
//     // Iterate through submissions
//     for (let s2 = 0; s2 < k.length; s2++) {
//       let u = k[s2];
//       let correct = 0;
//
//       // Iterate through guesses
//       for (let s3 = 0; s3 < users.length; s3++) {
//         let user = users[s3];
//         const ks = data[step][u][user];
//         console.log(ks);
//         if (ks) {
//           if (ks.correct) {
//             correct++;
//           }
//         }
//       }
//       if (correct < least_right) { //TODO: deal with ==
//         least_right = correct;
//         winner = data[step][u];
//         //most_time_left =
//       }
//     }
//   }
//   let percentage = (least_right / users.length)*100; // TODO: should be length - 1
//   console.log(percentage);
//   console.log(winner);
//   console.log(winner.url);
//   set(ref(database, '/games/' + game_id + '/hardest/'), {
//     question: winner.question,
//     type: winner.type,
//     url: winner.url,
//     percent: percentage,
//   }); // TODO: add time
// }

// Calculate hardest (longest time to guess)
// tiebreaker % right
function calculate_most_contentious(game_id, rounds, users, data) {

}


export default function end_game(game_id, rounds) {

  calculate_winner(game_id);

  // get users
  const userref = ref(database, 'games/' + game_id + "/users/");
  onValue(userref, (snapshot) => {
    const data = snapshot.val();
    const keys = Object.keys(data);
    calculate_best_of(game_id, rounds, keys);
  });
}
