import * as React from 'react';

import '../image.css';
import {useEffect, useState} from "react";

import {getStorage, uploadBytes, getDownloadURL} from "firebase/storage";
import {ref as storageref} from "firebase/storage";
import storage from '../../firebase_storage.js';
import { GiphyFetch } from '@giphy/js-fetch-api';
import G from './g.js'
import Box from '@mui/material/Box';


const giphy = new GiphyFetch(process.env.REACT_APP_GIPHY_KEY);


export default function GifPreview(props) {
  const [results, setResults] = useState(null);


    function setResult(result) {
      setResults(result.data);
    }
  const result = giphy.gif(props.thing).then(result => setResults(result.data));

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
      <G gif={results}/>
    </Box>
  );
}
