import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import LobbyPlayers from '../lobby_players';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';


import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


export default function PuzzleLobby(props) {
  // Whether the game has started or is in the lobby
  const [users, setUsers] = useState();
  const [gameId, setGameId] = useState();

  const setU = (u) => {
    setUsers(u);
  }


  // TODO: If admin, get a button to move forward
  // TODO: check if user already in lobby

  const startGame = () => {

    //flip state in database
    console.log("props.game_type");
    set(ref(database, '/valid_games/' + props.game_id), {
      started: true,
      ended: false,
      type: props.game_type,
    });

  };

  return (
      <Container component="main" maxWidth="xs">

        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Welcome to Mini Puzzle Hunt!
          </Typography>
          <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1"><a href="https://en.wikipedia.org/wiki/Puzzle_hunt">Puzzle Hunts</a> challenge teams to solve a series of increasingly difficult puzzles. In this mini puzzle hunt, you'll be presented with riddles, etc, etc. Your task is to come up with the answer first.</Typography>
        </Box>
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Players in Lobby:
          </Typography>
          <div>
          <LobbyPlayers game_id={props.game_id} setUsers={setU}/>
          </div>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
            onClick={startGame}
          >
            Start Game
          </Button>
          <Box sx={{mt:2}}>
            Don't worry, players can join once the game starts
          </Box>
        </Box>
        </Box>
      </Container>
  );
}
