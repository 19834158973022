import detective_bison from '../images/detective_bison.png';
import detective_bison_small from '../images/detective_bison_small.png';
import './signature.css';
import { styled } from '@mui/material/styles';

import CakeIcon from '@mui/icons-material/Cake';
import BottomNavigation from '@mui/material/BottomNavigation';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';

import ProgressiveImage from '../ProgressiveImage.js';

export default function SignatureMurder(props) {

  return (
    <Box sx={{ mt: 6}}>
          <Paper
          sx={{
            p: 2,
            margin: 'auto',

            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ButtonBase sx={{ maxWidth: '100%',
                maxHeight: '100%',}}>
                <ProgressiveImage
                  src={detective_bison}
                  placeholder={detective_bison_small}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Murder Mystery
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Coming soon
                  </Typography>

                </Grid>
                <Grid item>
                  <Button color="primary" disabled>
                    Coming Soon
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ mr: 3}}>
                  <Typography variant="subtitle1" component="div" color="#85bb65">
                    <b>$10 per person</b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
    </Box>
  );



}
