import * as React from 'react';
import {useEffect, useState} from "react";
import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

import GifPreview from './gif_preview.js';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function Easiest(props) {
  const [percent, setPercent] = React.useState();
  const [question, setQuestion] = React.useState();
  const [type, setType] = React.useState();
  const [url, setUrl] = React.useState();

  useEffect(() => { /// maybe you don't need this?
    const eref = ref(database, 'games/' + props.game_id +"/easiest/");
    onValue(eref, (snapshot) => {
      const data = snapshot.val();
      setPercent(data.percent);
      setQuestion(data.question);
      setType(data.type);
      setUrl(data.url);
    });
    return () => off(eref)
  }, []);

  return (
    <div>
    <Typography component="h1" variant="h6" align="center" sx={{ mb: 1 }}>
      {percent}% of you got this one right!
    </Typography>
      <Paper elevation="10" variant="elevation" sx={{ mt: 5, my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Typography component="h1" variant="h4" align="center">
          {question}
        </Typography>
        <Box align="center">
          <GifPreview type={type} thing={url} game_id={props.game_id}/>
        </Box >
      </Paper>
      </div>
  );
}
