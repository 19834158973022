import * as React from 'react';


import Typography from "@mui/material/Typography";


export default function PlayerRow(props) {

  let color;
  let g;
  if(props.data[props.username].mine == true) {
    color = "#000000";
    g = "";
  } else if (props.data[props.username].correct == true) {
    console.log("is this true?", props.data[props.username].correct)
    color = "#33b741";
    g = "guessed... " + props.data[props.username].guessed;
  } else if (props.data[props.username].correct == false) {
    color = "#b71c1c";
    g = "guessed... " + props.data[props.username].guessed;
  }


  return (
    <div>
          <Typography variant="h6" color={color}>
             {props.username} {g}
           </Typography>
      </div>
  );
}
