import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import storage from '../../firebase_storage.js';
import {getStorage, uploadBytes} from "firebase/storage";
import {ref as storageref} from "firebase/storage";

import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

import Typography from "@mui/material/Typography";
import PlayerRow from './player_row.js';
import {useEffect, useState} from "react";

export default function SubmissionCard(props) {
  const [players, setPlayers] = React.useState();
  const [num, setNum] = React.useState();

  const handleNext = () => {
    if (props.step >= (num-1)) {
      props.nextRound();
    } else {
      props.nextStep();
    }
  };

  useEffect(() => { /// maybe you don't need this?
    const uref = ref(database, 'games/' + props.game_id +"/users/");
    onValue(uref, (snapshot) => {
      const data = snapshot.val();
      const players =  Object.keys(data).map((user, step) => {
              return (
                  <PlayerRow username={user} data={data}/>
                )
            });
      setNum(Object.keys(data).length);
      setPlayers(players);
    });

    return () => off(uref)
  }, []);


  return (
    <div>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper elevation="10" variant="elevation" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Score
          </Typography>
          {players}
          <Button
            variant="contained"
            onClick={handleNext}
            color="primary"
            sx={{ mt: 3, ml: 1 }}
          >
            Next
          </Button>
        </Paper>
      </Container>
      </div>
  );
}
