import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import LobbyPlayers from '../lobby_players';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';


import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


export default function HotseatLobby(props) {
  // Whether the game has started or is in the lobby
  const [users, setUsers] = useState();
  const [gameId, setGameId] = useState();
  const [disable, setDisabled] = useState(true);

  const setU = (u) => {
    setUsers(u);
    setDisabled(u.length < 2);
  }


  // TODO: If admin, get a button to move forward
  // TODO: check if user already in lobby

  const startGame = () => {

    //flip state in database
    set(ref(database, '/valid_games/' + props.game_id), {
      started: true,
      ended: false,
      type: props.game_type,
    });

  };

  return (
      <Container component="main" maxWidth="xs">

        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Welcome to Hotseat!
          </Typography>
          <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1"><b>Instructions:</b> Choose a player to put in the hotseat. A popup will appear on a random player's screen, prompting them to chose a category, and then a question within that category. The person in the hotseat must then answer the question. Chose a new person to be in the hotseat at any time, or chose party mode where the hotseat user will be chosen randomly each round.</Typography>
        </Box>
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Players in Lobby:
          </Typography>
          <div>
          <LobbyPlayers game_id={props.game_id} setUsers={setU}/>
          </div>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
            onClick={startGame}
            disabled={disable}
          >
            Start Game
          </Button>
          <Box sx={{mt:2}}>
            Need at least 2 players to start game <br/>
            <br/>
            Don't worry, players can join once the game starts
          </Box>
        </Box>
        </Box>
      </Container>
  );
}
