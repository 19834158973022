
/// X Guessed.... Y! 0 points

import * as React from 'react';
import Box from '@mui/material/Box';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import ScoreCard from './score_card';

//const theme = createTheme();

//forward_hedgehog

export default function GuessView(props) {
  

  return (
    <Box sx={{ mt: 12 }}>
      <Box sx={{ mt: 2 }}>
        <ScoreCard nextRound={props.nextRound} nextStep={props.nextStep} round={props.round} step={props.step} username={props.username} game_type={props.game_type} game_id={props.game_id}/>
      </Box>
    </Box>
  );

}
