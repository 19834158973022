import React, {useEffect} from "react";
import PlayerComponent from "./PlayerComponent";

export default function Circle(props) {
  const [square, setSquare] = React.useState([]);
  const [mounted, setMounted] = React.useState(false);


    useEffect(() =>{
      const num = props.users.length;

      const type = 1;
      let radius = "170"; //distance from center
      let start = 0; //shift start from 0
      let slice = (360 * type) / num;

      let items = [];
      let i;
      for (i = 0; i < num; i++) {
        let rotate = slice * i + start;
        let rotateReverse = rotate * -1;

        items.push({
          radius: radius,
          rotate: rotate,
          rotateReverse: rotateReverse
        });
      }
      setSquare(items)

    },[]);


    return (
      <div>
        <div className="circle">
          <div className="circle-hold">
            {square.map(function (value, index) {
              return <PlayerComponent css={value} user={props.users[index]} num={index + 1} hotseatUser={props.hotseatUser} askingUser={props.askingUser}/>;
            })}
          </div>
        </div>
      </div>
    );
}
