
import { GiphyFetch } from '@giphy/js-fetch-api';
import {Grid} from '@giphy/react-components';
import React, { useState } from "react";
// import { useAsync } from "react-async-hook";
import { render } from "react-dom";
import Error from './Error'

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ResizeObserver from "react-resize-observer";

import giphy_logo from '../../images/GIPHY_Logo.png';

const giphy = new GiphyFetch(process.env.REACT_APP_GIPHY_KEY)

// TODO: Need to attribute giphy in app

function GridDemo(props) {
  const fetchGifs = (offset: number) =>
    giphy.search(props.search, { offset, limit: 10 });
  console.log(props.search);
  const [width, setWidth] = useState(window.innerWidth);
  return (
    <>
      <Grid
        onGifClick={props.onGifClick}
        fetchGifs={fetchGifs}
        width={width}
        columns={3}
        gutter={6}
      />
      <ResizeObserver
        onResize={({ width }) => {
          setWidth(width);
        }}
      />
    </>
  );
}

export default function GifSearch(props) {
  const [text, setText] = useState('')
  const [err, setErr] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [gif, setGif] = useState()

  const handleInput = (e) => {
    setText(e.target.value)
  }

  const onGifClick = (gif, e) => {
    setGif(gif);
    props.setPreview("gif", gif);
    e.preventDefault();
    setOpen(false);
  }

  const handleSubmit = (e) => {
    if(text.length === 0) {

      //set error state to true
      setErr(true)
      return
    }
    setErr(false)
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div className="App">
      <TextField id="outlined-basic" value={text} onChange={handleInput} label="Search for gifs" variant="outlined" />
      <Button variant="contained" onClick={handleSubmit} color="secondary">Search</Button>
      Powered by <img src={giphy_logo} width={40}/>
      <Error isError={err} text='need length longer than 0 for input'/>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Choose a Gif</DialogTitle>
        <DialogContent dividers={true}>
        <GridDemo
                search={text}
                onGifClick={onGifClick}
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}
