import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

var questions = [
        'Worst movie ever made',
        'Best album ever made',
        'Most iconic 2000s moment',
        'Saddest celebrity death',
        'Your spirit animal',
        'Landmark you most want to see',
        'Pet you least want to have',
        'Best of the 7 wonders of the world',
        'The one luxury you could not go without',
        'If you could only drink one type of drink (besides water) for the rest of your life, it would be...',
        'Movie most likely to win best picture in the 20…',
        'Favorite SNL cast member',
        'Movie you have seen the most times',
        'Most heartbreaking celebrity breakup',
        'Favorite holiday',
        'Cutest animal',
        'Favorite sports icon',
        'Best meme',
        'Most beautiful tree',
        'Worst movie that has won best-picture at the os…',
        'Best disney movie',
        'Best Disney princess',
        'Favorite bachelor',
        'If you were a breed of dog you would be…',
        'Most romantic place in the world',
];


function getRounds(num_users) {
  if (num_users == 1) {
    console.log("not enough players")
    return 0;
  }
  if (num_users > 1 && num_users < 5) {
    return 5;
  }
  if (num_users == 5) {
    return 4;
  }
  if (num_users > 5 && num_users < 8) {
    return 3;
  }
  return 2;
}

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function matchQuestionsToRounds(users, questions, rounds, game_id) {
  for (let step = 1; step < rounds + 1; step++) {
    // For each round, populate information
    let user_order = shuffle(users);
    for (var i = 0; i < user_order.length; i++) {
      // For each user, get their question and save to database
      let question = questions[((user_order.length*(step-1))+i)]

      set(ref(database, 'games/' + game_id + "/rounds/" + step + "/" + i + "/"), {
        user: user_order[i],
        question: question,
      });
      set(ref(database, 'games/' + game_id +"/roundquestions/" + step + "/" + user_order[i] + "/"), {
        question: question,
      });
    }
  }
}

export default function setup_pop_game(game_id, users) {
  // get number of rounds
  let rounds = getRounds(users.length);

  set(ref(database, '/games/' + game_id + '/state/'), {
    round: 1,
    rounds: rounds,
    step: 0,
    steps: users.length,
    view: "submission",
  });
  matchQuestionsToRounds(users, questions, rounds, game_id);
}
