// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getDatabase} from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwQcuYyBDTr718w6QhCV1cYMe-qf-oDP4",
  authDomain: "party-bison.firebaseapp.com",
  databaseURL: "https://party-bison-default-rtdb.firebaseio.com",
  projectId: "party-bison",
  storageBucket: "party-bison.appspot.com",
  messagingSenderId: "580603264916",
  appId: "1:580603264916:web:320de68ee1683f0de31981",
  measurementId: "G-2ZLFWZTR81"
};


// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const database = getDatabase(firebase);
const analytics = getAnalytics(firebase);

export default database;
