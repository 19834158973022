import * as React from 'react';

import '../image.css';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {useEffect, useState} from "react";

import GuessPlayers from './guess_players.js';
import GuessPreviewContainer from './guess_preview_container.js';

// import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';



import {getStorage, uploadBytes, getDownloadURL} from "firebase/storage";
import {ref as storageref} from "firebase/storage";
import storage from '../../firebase_storage.js';

import database from '../../firebase_database.js';
import {getDatabase, set, ref, onValue, off, push} from "firebase/database";



export default function GuessCard(props) {
  const [question, setQuestion] = React.useState();
  const [subUser, setSubUser] = React.useState();

    const handleNext = () => {
      props.setScore();
    };

    useEffect(() => {
      const qref = ref(database, 'games/' + props.game_id + "/rounds/" + props.round + "/"+ props.step);
      onValue(qref, (snapshot) => {
        const data = snapshot.val();
        setQuestion(data.question);
        setSubUser(data.user);
      });
      return () => off(qref)
    }, []);

    let show;
    if (subUser) {
      show =
      <div>
      <GuessPreviewContainer game_id={props.game_id} round={props.round} subUser={subUser}/>
      <Grid container item  xs={12} lg={7} justifyContent="center" alignItems="flex-start" mx="auto">
      </Grid>
      <GuessPlayers seconds={props.seconds} step={props.step} round={props.round} subUser={subUser} game_id={props.game_id} username={props.username}/>
      </div>
    }


  return (
    <div>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper elevation="10" variant="elevation" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            {question}
          </Typography>
          {show}
        </Paper>
      </Container>
      </div>
  );
}
