import * as React from 'react';

import '../image.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import GifSearch from './gif_search.js';
import Preview from './preview.js';

import storage from '../../firebase_storage.js';
import {getStorage, uploadBytes} from "firebase/storage";
import {ref as storageref} from "firebase/storage";

import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

// import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import UploadButtons from './uploader.js'
const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');


export default function SubmissionCard(props) {

  const [activeStep, setActiveStep] = React.useState(0);
  const [preview, setPreview] = React.useState();
  const [previewType, setPreviewType] = React.useState();
  const [image, setImage] = React.useState();



  const pushName = () => {
    // set answer in data based
    if (previewType == "gif") {
      console.log(preview);
      set(ref(database, '/games/' + props.game_id + '/roundquestions/' + props.round + "/" + props.username), {
        question: props.question,
        type: "gif",
        url: preview.id,
      });
   // TODO: figure out how to show gif from just URL NOT gif object
    } else if (previewType == "picture") {
      const imagesref = storageref(storage, 'games/' + props.game_id + "/" + props.round+  "/" + props.username);
      uploadBytes(imagesref, image).then((snapshot) => {
        console.log('Uploaded image!');
        set(ref(database, '/games/' + props.game_id + '/roundquestions/' + props.round + "/" + props.username), {
          question: props.question,
          type: "picture",
          url: null,
        });
      });
    }
    props.setGuess();

    // set yourself as submitted
    set(ref(database, '/games/' + props.game_id + '/submitted/' + props.round + "/" + props.step+ "/" + props.username), {
      guessed: true
    });
  };

  const setPr = (type, p) => {
    setPreview(p);
    setPreviewType(type);
  }

  const setI = (i) => {
    setImage(i);
  }

  return (
    <div>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper elevation="10" variant="elevation" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            {props.question}
          </Typography>
          <Typography component="h1" variant="h5" align="center">
            Please answer in the form of a picture or gif
          </Typography>
          <Grid container item  xs={12} lg={7} justifyContent="center" alignItems="flex-start" mx="auto">
          <Preview type={previewType} thing={preview}/>
          <GifSearch setPreview={setPr}/>
          <br/>
          <UploadButtons setPreview={setPr} setI={setI}/>
          </Grid>
          <Button
            variant="contained"
            onClick={pushName}
            color="primary"
            sx={{ mt: 3, ml: 1 }}
          >
            Submit
          </Button>
        </Paper>
      </Container>
      </div>
  );
}
