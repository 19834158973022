import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import SubmissionCard from './submission_card';
import Timer from '../timer.js';
import Box from '@mui/material/Box';

//const theme = createTheme();

export default function SubmissionView(props) {
  const [question, setQuestion] = React.useState();


  useEffect(() => {
    const roundsRef = ref(database,'games/' + props.game_id +"/roundquestions/" + props.round + "/" + props.username);
    onValue(roundsRef, (snapshot) => {
      const data = snapshot.val();
      setQuestion(data.question);
    });

    return () => off(roundsRef)
  }, []);

  const setSec = () => {
    console.log("sec");
  }


  return (
    <Box sx={{ mt: 5 }}>
      <Timer timerRunout={props.timerRunout} startingSeconds={100} setSec={setSec}/>
      <Box sx={{ mt: 2 }}>
        <SubmissionCard setGuess={props.setGuess} step={props.step} game_id={props.game_id} round={props.round} username={props.username} question={question}/>
      </Box>
    </Box>
  );

}
