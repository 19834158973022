import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

import SubmissionView from './Submit/submission_view.js';
import GuessView from './Guess/guess_view.js';
import ScoreView from './Score/score_view.js';
import EndView from './End/end_view.js';
import GridDemo from './Submit/gif_search.js';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SubmissionSidebar from './Submit/submission_sidebar';
import GuessSidebar from './Guess/guess_sidebar';

import Particles from "react-tsparticles";
import particleConfig from "./particlesjs-config.json";
import './particles.css';
//const theme = createTheme();

export default function GameContainer(props) {
  const [gameState, setGameState] = useState();


  const setGuess = () => {
    // setView("guess");
    console.log("setGuess");
  }

  const nextRound = () => {
    let v;
    let new_round;
    let new_step;

    if (gameState.round == gameState.rounds) {
      v = 'end';
      new_round = gameState.round;
      new_step = gameState.step;
    } else {
      v = 'submission';
      new_round = gameState.round + 1;
      new_step = 0;
    }

    set(ref(database, '/games/' + props.game_id + "/state/"), {
      round: new_round,
      rounds: gameState.rounds,
      step: new_step,
      steps: gameState.steps,
      view: v,
    });

  }

  const nextStep = () => {
    let new_step = gameState.step + 1;
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      round: gameState.round,
      rounds: gameState.rounds,
      step: new_step,
      steps: gameState.steps,
      view: 'guess',
    });
  }

  const setScore = () => {
    console.log("setScore");
    // setView("score");
  }

  const sumbissionTimerRunout = () => {
    //move on to guess view
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      round: gameState.round,
      rounds: gameState.rounds,
      step: gameState.step,
      steps: gameState.steps,
      view: "guess",
    });
  }

  const everyoneSubmitted = () => {
    //move on to guess view
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      round: gameState.round,
      rounds: gameState.rounds,
      step: gameState.step,
      steps: gameState.steps,
      view: "guess",
    });
  }

  const guessTimerRunout = () => {
    //move on to score view
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      round: gameState.round,
      rounds: gameState.rounds,
      step: gameState.step,
      steps: gameState.steps,
      view: "score",
    });
  }

  const everyoneGuessed = () => {
    //move on to score view
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      round: gameState.round,
      rounds: gameState.rounds,
      step: gameState.step,
      steps: gameState.steps,
      view: "score",
    });
  }

  const scoreViewNext = () => {
    //either increase step or move to next round

  }

  // TODO: Use effect - if everyone has finished the round move to the next screen, control everything from state
  useEffect(() => {
    const stateref = ref(database, 'games/' + props.game_id + "/state/");
    onValue(stateref, (snapshot) => {
      const data = snapshot.val();
      setGameState(data);
    });
    return () => off(stateref)
  }, []);


  let v;
  let sidebar;
  let round_message;
  if (!gameState) {
      v = <div> Loading </div>
      sidebar = <div>Loading...</div>
  } else {
    round_message = <Typography variant="h5">Round: {gameState.round}/{gameState.rounds}</Typography>
    if (gameState.view == 'submission') {
      v = <SubmissionView timerRunout={sumbissionTimerRunout} step={gameState.step} setGuess={setGuess} username={props.username} round={gameState.round} game_type={props.game_type} game_id={props.game_id}/>
      sidebar = <SubmissionSidebar users={props.users} everyoneSubmitted={everyoneSubmitted} username={props.username} game_id={props.game_id} round={gameState.round} step={gameState.step}/>
    } else if (gameState.view == 'guess') {
      v = <GuessView timerRunout={guessTimerRunout} setScore={setScore} step={gameState.step} round={gameState.round} username={props.username} game_type={props.game_type} game_id={props.game_id}/>
      sidebar = <GuessSidebar users={props.users} username={props.username} everyoneGuessed={everyoneGuessed} game_id={props.game_id} round={gameState.round} step={gameState.step}/>
    } else if (gameState.view == 'score') {
      v = <ScoreView nextStep={nextStep} round={gameState.round} nextRound={nextRound} step={gameState.step} round={gameState.round} username={props.username} game_type={props.game_type} game_id={props.game_id}/>
      sidebar = <div> </div>
    } else if (gameState.view == 'end') {
      v = <EndView game_id={props.game_id} rounds={gameState.rounds}/>
      sidebar = <div> </div>
    }
  }



  return (
    <Grid container spacing={2}>
    <Particles
        style={{
          width: "100%",
          minHeight: "100vh",
          height: "100%",
          zIndex: "-1",
          position: "fixed",
        }}
        params={particleConfig}
      />
        <Grid item xs={12} md={2}>
          <Box sx={{mt:4, ml:4}}>
            <Typography variant="h5">
              #{props.game_id} <br/>
              {props.username} <br />
              {round_message}
            </Typography>
          </Box>
          </Grid>
        <Grid item xs={12} md={10}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={9}>
      {v}
      </Grid>
      <Grid item xs={12} md={3}>
        <Box sx={{mt:12, ml:2}}>
          <Typography variant="h5">
            {sidebar}
          </Typography>
        </Box>
        </Grid>
      </Grid>
      </Grid>
      </Grid>
  );
}
