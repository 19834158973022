import React from "react";
import fire from '../images/fire.gif';
import crown from '../images/crown.png';
//<b>hotseat</b> {hotseatUser} <br/><img src={fire} width="50" />

// <br/> <br/>
// <b>asking </b>{askingUser}<br/> <img src={crown} width="30" />
export default function PlayerComponent(props) {

  let symbol;
  if (props.user == props.hotseatUser) {
    symbol = <img src={fire} width="50" />
  } else if (props.user == props.askingUser) {
    symbol = <img src={crown} width="30" />
  }

    const css = props.css;
    return (
      <div
        className="square"
        style={{
          transform:
            "rotate(" +
            css.rotate +
            "deg) translate(" +
            css.radius +
            "px) rotate(" +
            css.rotateReverse +
            "deg)"
        }}
      >
      <div>
        {props.user}
        </div>
        {symbol}
      </div>
    );
}
