import hotseat_bison from '../images/hotseat_bison.png';
import hotseat_bison_small from '../images/hotseat_bison_small.png';

import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import BottomNavigation from '@mui/material/BottomNavigation';

import ProgressiveImage from '../ProgressiveImage.js';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function IcebreakerHotseat(props) {

  const gen = () => {
    props.generateGame("hotseat");
  }

  return (
    <Box sx={{ mt: 6}}>
          <Paper
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ButtonBase sx={{   maxWidth: '100%',
                maxHeight: '100%', }}>
                <ProgressiveImage
                  src={hotseat_bison}
                  placeholder={hotseat_bison_small}
            
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    HotSeat
                  </Typography>
                  <Typography variant="body" gutterBottom>
                    A get-to-know-you game. Put a player in the "hot seat" and take turns chosing questions they must answer. Some are hypothetical, some are silly, and some will lead to interesting stories.
                    <br/>
                  </Typography>
                  <Box sx={{mt: 3, mb:3}}>
                  <Typography variant="body" color="text.secondary">
                    This Icebreaker is: <br/>
                      <Box sx={{ ml: 6, mt:1}}>
                      <AcUnitIcon className="listitem2"/> Great when someone new joins the group <br/>
                      <AcUnitIcon className="listitem2"/> A loud, interactive game <br/>
                      <AcUnitIcon className="listitem2"/> For two to ten players  <br/>
                      <AcUnitIcon className="listitem2"/> Great for breaking the ice <br/>
                      </Box>
                  </Typography>
                  </Box>
                  <Typography variant="body">
                    <b>Instructions: </b> Choose a player to put in the hotseat. A popup will appear on a random player's screen, prompting them to chose a category, and then a question within that category. The person in the hotseat must then answer the question. Chose a new person to be in the hotseat at any time, or chose party mode where the hotseat user will be chosen randomly each round.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button color="primary" onClick={gen}>
                     Create Game
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box sx={{ mr: 3}}>
                  <Typography variant="subtitle1" component="div" color="#85bb65">
                    <b>Free</b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
    </Box>
  );
}
