import React, {useEffect} from "react";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import {TwitterTweetEmbed} from 'react-twitter-embed';

import bison from '../images/bison.gif';
import laptops from '../images/laptops.jpg';
import laptops_small from '../images/laptops_small.jpg';
import team from '../images/team.jpg';
import team_small from '../images/team_small.jpg';

import { BackgroundImage } from 'react-image-and-background-image-fade';

import './landing.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CakeIcon from '@mui/icons-material/Cake';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import GameShell from '../game_shell.js';
import TextLoop from './textloop.js'

import {Route, Routes} from 'react-router-dom';



function LandingSmall(props) {
  const [laptopsPic, setLaptopsPic] = React.useState(laptops_small);
  const [loadingLaptops, setLoadingLaptops] = React.useState(true);
  const [loadingTeam, setLoadingTeam] = React.useState(true);
  const [teamPic, setTeamPic] = React.useState(team_small);


  useEffect(() => {

    // start loading original laptops image
    const imageToLoad = new Image();
    imageToLoad.src = laptops;
    imageToLoad.onload = () =>
      // When image is loaded replace the image's src and set loading to false
      setLaptopsPic(laptops);
      setLoadingLaptops(false);

    // start loading original team image
    const imageToLoad2 = new Image();
    imageToLoad2.src = team;
    imageToLoad2.onload = () =>
      // When image is loaded replace the image's src and set loading to false
      setTeamPic(team);
      setLoadingTeam(false);
  });

  return (
    <div>
    <Box sx={{ }}>
    <Box sx={{ width: '100%',  backgroundColor: '#fafac4',             display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',}}>
            <Box sx={{ ml: 5, mt: 3, display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',}}>

              <Typography variant="h2">
              Your next team event should be
              <Typography variant="h2" color="primary">
                <TextLoop/>
                </Typography>
              </Typography>



              </Box>

            <Box sx={{
                width: '50vh',
                height:'44vh',
                alignItems: "center"
              }}>
                <Box
                  height="100%"
                  width="100%"
                  sx={{
                    backgroundImage: 'url('+bison+')',
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",


                  }}
                ></Box>
              </Box>

        </Box>
        </Box>
        <Divider/>
        <Divider/>
        <BackgroundImage
                src={laptopsPic}
                style={{
                  opacity: loadingLaptops ? 0.5 : 1,
                  transition: "opacity .15s linear",
                  minHeight: '100vh',
                  minwidth: '100vh',
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  display: "grid",
                  placeItems: "center",
                }}
              >

            <Box  sx={{mt: 5, backgroundColor: '#fbfbc9' }}>
            <Box sx={{  mt: 5, ml: 5, mr: 5, mb: 5}}>
            <Typography variant="subtitle">
            Remote teams are teams. <br/><br/> They should feel like it. <br/> <br/>
            People work better together when they feel like they know each other. <br/> <br/>
            In a remote-first world, it's even more important for your team to kick back and take a break.
            </Typography>
            </Box>
            </Box>

        </BackgroundImage>
        <Divider/>
        <Box
          sx={{
            mt: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

          }}
        >
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ml:3,
          mr:3,
          mb: 6,
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">
        Any of these sound familiar?
        </Typography>
        <br/>
          <Typography variant="subtitle">
          Virtual team events feel more like work than play
          </Typography>
          <br/>
          <Typography variant="subtitle">
          One conversation thread dominates the entire experience
          </Typography>
          <br/>
          <Typography variant="subtitle">
          You walk away knowing very little about your teammates
          </Typography>
          <br/>
          <Typography variant="subtitle">
          Planning a team event is overwhelming
          </Typography>
          <br/>
          <Typography variant="subtitle">
          MCing a team event prevents you from having any fun
          </Typography>
        </Box>
        <BackgroundImage
                src={teamPic}
                width="100%"
                style={{
                  opacity: loadingTeam ? 0.5 : 1,
                  transition: "opacity .15s linear",
                  backgroundSize: "cover",
                  minHeight: '100vh',
                  backgroundPosition: "top",
                  display: "grid",
                  placeItems: "center",
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >

          <Box sx={{  mt: 10, ml: 5, mr: 5, mb: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',}}>
          <Typography variant="h2" color="primary">
           There's a better way
          </Typography>
          <Typography variant="h5" color="secondary">
            Party Bison has designed a set of games, perfect for virtual teams.
          </Typography>
          </Box>

          </BackgroundImage>



            <Box sx={{mt: 10, display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'}}>

              <Link
                href="Icebreakers"
                sx={{ my: 1, mx: 1.5 }}
                underline="hover"
              >
                <Typography variant="h4" color="primary">
                Icebreakers 🧊☄️

                </Typography>
                </Link>
                <br/>
                  <Typography variant="subtitle">
                  100% Free
                  </Typography>
                  <br/>
                  <Typography variant="subtitle">
                  1-15 Players
                  </Typography>
                  <br/>
                  <Typography variant="subtitle">
                  Play for 5-50 minutes
                  </Typography>
                  <br/>
                  <Typography variant="subtitle">
                  Break the ice and warm your team up
                  </Typography>
                  <br/>

              <Grid item xs={6}>
              <Box sx={{mt: 5, ml:2, mr:2, display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'}}>
              <Link

                href="SignatureEvents"
                sx={{ my: 1, mx: 1.5 }}
                underline="hover"
              >
              <Typography variant="h4" color="primary">
                 Signature Events &#127881; &#128003;
              </Typography>
              </Link>
              <br/>
                <Typography variant="subtitle">
                Play with your team and win prizes
                </Typography>
                <br/>
                <Typography variant="subtitle">
                30-90 minutes of dedicated time
                </Typography>
                <br/>
                <Typography variant="subtitle">
                Challenges your team to get to know each other better
                </Typography>
                <br/>
                <Typography variant="subtitle">
                3-10 Players
                </Typography>
                <br/>
                </Box>
            </Grid>
            </Box>
            <Box minHeight="40vh" sx={{ width: '90%', mt: 5, mb: 10, backgroundColor: '#ccd3d4' }}>
              <Box sx={{  mt: 10, ml: 5, mr: 5, mb: 10}}>
              <Box sx={{mb: 7}}>
                <Typography variant="h4">
                  See what people are saying about us:
                </Typography>
              </Box>
              <TwitterTweetEmbed
                tweetId={'1559974599969984517'}
              />
              </Box>
              <Box sx={{  mt: 10, ml: 5, mr: 5, mb: 10}}>
              <TwitterTweetEmbed
                tweetId={'1559980509777735680'}
              />
              </Box>
              <Box sx={{  mt: 10, ml: 5, mr: 5, mb: 10}}>
              <TwitterTweetEmbed
                tweetId={'1560029669595955201'}
              />
              </Box>
            </Box>
            <Box sx={{mb: 10}}>
            <Button href="form" color="primary" size="large" variant="contained" sx={{ my: 1, mx: 1.5}}>
              Join Existing Game Now
            </Button>
            </Box>
          </Box>
          <footer>
            <Box
              sx={{
                m2: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
            <Box>
              Made with ♥ by Party Bison
              </Box>
              <Box>
              Party Bison is a one woman effort, help by <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdar9S8OXCyy-UGLvXFjeRznhrGB2ZD_80Tw8NikGjoWB7x2g/viewform?usp=sf_link">Giving Feedback</Link>

              </Box>
            </Box>
        </footer>
    </div>
  );

}

///games/4

export default LandingSmall;
