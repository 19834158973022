import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function DetailsForm(props) {
  const [number, setNumber] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setNumber(event.target.value);
    props.setNumber(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let first_name = data.get('firstName');
    let last_name = data.get('lastName');
    let email = data.get('email');
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
      return
    }
    if (first_name == ''){
      setFirstNameError(true);
      return
    } else {
      setFirstNameError(false);
    }
    if (last_name == '') {
      setLastNameError(true);
      return
    } else {
      setLastNameError(false);
    }
    props.setEmail(email);
    props.setLastName(last_name);
    props.setFirstName(first_name);

    props.handleNext();

  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Details
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            error={firstNameError}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            error={lastNameError}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email Address"
            fullWidth
            variant="standard"
            error={emailError}
          />
        </Grid>
        <Grid item xs={12} sx={{          display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',}}>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Players</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={number}
              label="Number"
              onChange={handleChange}
            >
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>
          </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <b>warning: </b>you can play with less than this amount, but you will not be able to add players later
        </Typography>
      </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained"
          type="submit"
          sx={{ mt: 3, ml: 1 }}
          >
            Next
          </Button>
      </Box>
      </Box>
    </React.Fragment>
  );
}
