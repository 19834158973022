import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';


const Timer = () => {
  const [seconds, setSeconds] = useState(300);
  const [timeLeft, setTimeLeft] = useState();
  const [isActive, setIsActive] = useState(true);

  function toggle() {
    setIsActive(!isActive);
  }

  function reset() {
    setSeconds(300);
    setIsActive(false);
  }

  const calculateTimeLeft = () => {

    let timeLeft = {};

    if (seconds > 0) {
      timeLeft = {
        minutes: Math.floor((seconds / 1000 / 60) % 60),
        seconds: Math.floor((seconds / 1000) % 60)
      };
    }
    return timeLeft;
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    }
    if (seconds > 0) {
      setTimeLeft({
        minutes: Math.floor((seconds / 1000 / 60) % 60),
        seconds: Math.floor((seconds / 1000) % 60)
      });
    }
    if (seconds == 0) {
      clearInterval(interval);
      toggle();
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div className="app">
      <div className="time" align="center">
        <Typography component="h1" variant="h5">
          {seconds}s
        </Typography>
      </div>
      <div className="row">
      </div>
    </div>
  );
};

export default Timer;
