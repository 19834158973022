import * as React from 'react';

import {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import GameContainer from './game_container.js';
import HotseatLobby from './hotseat_lobby.js';
import Modal from '@mui/material/Modal';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

// TODO: control the view based on database values, if someone comes in late do something?

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Hotseat(props) {
  const [users, setUsers] = useState();
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  let arrow1 = '<-';
  let arrow2 = '->';

  useEffect(() => {
    const usersref = ref(database, 'games/' + props.game_id +"/users/");
    onValue(usersref, (snapshot) => {
      const data = snapshot.val();
      setUsers(Object.keys(data));
    });
    return () => off(usersref)
  }, []);


  let show;
  if (props.started == false) {
    show =         <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Box sx={{mt:4, ml:2, mr: 2}}>
                    <Typography variant="h5">
                      #{props.game_id} <br/>
                      {props.user_id} <br />
                    </Typography>
                  </Box>
                  </Grid>
                <Grid item xs={12} md={10}>
                  <Box sx={{mt:6, ml:2}}>
                  <HotseatLobby game_id={props.game_id} game_type={"hotseat"}/>
                  </Box>
                </Grid>

              </Grid>
  } else if (props.started == true) {
    show = <div>
    <GameContainer users={users} game_id={props.game_id} user_id={props.user_id}/>
        <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b> Step 1:</b> Assign someone to be in the hotseat with the button in the bottom right part of the screen {arrow2}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {arrow1} <b> Step 2:</b> The person in the hotseat should click next question
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <b> Step 3:</b> A random user will be given the crown and presented with a list of topics to chose from.
        </Typography>
      </Box>
    </Modal>
    </div>
  } else {
    show = <div>Something went wrong... </div>
  }

  return (
      <div id="bounce1">
              {show}
      </div>
  );

}
// <Grid container spacing={2}>
// <Grid item xs={12} md={2}>
//   <Box sx={{mt:4, ml:4}}>
//     <Typography variant="h5">
//       #{props.game_id} <br/>
//       {props.username} <br />
//       score: {props.score}
//     </Typography>
//   </Box>
//   </Grid>
// <Grid item xs={12} md={10}>
// <Grid container spacing={2}>
// <Grid item xs={12} md={9}>
// {v}
// </Grid>
// <Grid item xs={12} md={3}>
// <Box sx={{mt:12, ml:2}}>
//   <Typography variant="h5">
//     {sidebar}
//   </Typography>
// </Box>
// </Grid>
// </Grid>
// </Grid>
