import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Timer from './timer.js';
import GuessList from './guess_list.js';
import GuessMessage from './guess_message.js';


import qa from './qa.js';


//const theme = createTheme();

export default function PuzzleGameContainer(props) {
  const [players, setPlayers] = useState();
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [correct, setCorrect] = useState();
  const [show, setShow] = useState();
  const [hint, setHint] = useState();

  const nextQuestion = () => {
    let int = Math.floor(Math.random() * 7);
    let q = qa[int];
    set(ref(database, 'games/' + props.game_id +"/qa/"), {
      question: q.question,
      answer: q.answer,
    });
    // for user in Players
    set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
      next: true,
    });

  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let guess = data.get('guess').toLowerCase();
    if (guess == answer) {
      setCorrect(true);
      set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
        correct: true,
        next:false
      });
    } else {
      setCorrect(false);
      set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
        correct: false,
        next: false,
      });
    }
    setShow(true);

  }

  const dontShow = () => {
    setShow(false);
  }

  useEffect(() => {
    const qref = ref(database, 'games/' + props.game_id +"/qa/");
    onValue(qref, (snapshot) => {
      const data = snapshot.val();
      setQuestion(data.question);
      setAnswer(data.answer);
      const myArray = data.answer.split(" ");
      const unit = "_ ";
      for (var i = 0; i < myArray.length; i++) {
        const word = unit.repeat(myArray[i].length);
      }
      const words =  myArray.map((word, step) => {
              return (
                  <div>
                    {unit.repeat(word.length)} <br/>
                  </div>
                )
            });
      setHint(words);

    });


    return () => off(qref)
  }, []);

  let show_message;
  if (show) {
    show_message = <GuessMessage correct={correct} dontShow={dontShow} show={show}/>
  }


  return (
    <Box sx={{ mt: 12 }}>
      <Box sx={{ mt: 2 }}>
        <Typography component="h1" variant="h5" align="center">
          {question}
        </Typography>
        <Typography component="h1" variant="h5" align="center">
          {hint}
        </Typography>

      </Box>
        <Container component="main" maxWidth="sm" sx={{ mb: 4}}>
          <Box sx={{ mt: 2}}>
            <Button justifyContent="center" onClick={nextQuestion} align="center">Go To Next Puzzle</Button>
          </Box>
          <Paper elevation="10" variant="elevation" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box component="form" onSubmit={handleSubmit}  noValidate sx={{ mt: 1 }}>
              <TextField
                id="outlined-basic"
                id="guess"
                name="guess"
                fullWidth="true"
                justifyContent="center"
                align="center"
                variant="outlined" />
                {show_message}
            </Box>
          </Paper>
        </Container>
      <Box sx={{ mt: 2 }} align="center">
        <GuessList game_id={props.game_id}/>
      </Box>
    </Box>
  );
}
