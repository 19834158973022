import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import Guess from './guess.js'
import Box from '@mui/material/Box';


//const theme = createTheme();

export default function GuessList(props) {
  const [players, setPlayers] = useState();


  // TODO: players list should be passed in... this should pull
  useEffect(() => {
    const uref = ref(database, 'games/' + props.game_id +"/users/");
    onValue(uref, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      const players =  Object.keys(data).map((user, step) => {
              return (
                  <Guess user={user} key={user} next={data[user].next} correct={data[user].correct}/>
                )
            });
      setPlayers(players);
    });

    return () => off(uref)
  }, []);

  return (
    <Box sx={{ mt: 2 }}>
      {players}
    </Box>
  );

}
