import * as React from 'react';
import Typography from "@mui/material/Typography";
import Easiest from './easiest.js';
import Hardest from './hardest.js';

import Container from '@mui/material/Container';


export default function GameSummary(props) {

  let headline;
  let card;
  if (props.type == "easiest") {
    headline = "Easiest";
    card = <Easiest game_id={props.game_id}/>
  } else if (props.type == "hardest") {
    headline = "Hardest";
    card = <Hardest game_id={props.game_id}/>
  } else if (props.type == "contentious") {
    headline = "Most Contentious";
    card = <Easiest game_id={props.game_id}/>
  }

  return (
    <div>
      <Container component="main" color="#fafac4" maxWidth="sm" sx={{ mb: 4, backgroundColor: '#fafac4' }}>
      <Typography component="h1" variant="h4" align="center" sx={{ mb: 1 }}>
        {headline}
      </Typography>
      {card}
      </Container>
      </div>
  );
}
