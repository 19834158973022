import React from "react";

import XDetails from './x_details.js';
import Checkout from '../Checkout/Checkout.js';

export default function LearnMore(props) {
  const [checkout, setCheckout] = React.useState(false);
  const [gameCode, setGameCode] = React.useState();

  const setC = () => {
    setCheckout(true);
  }

  const setGC = (code) => {
    setGameCode(code);
  }

  let view;
  if (checkout) {
    view = <Checkout gameCode={gameCode}/>
  } else {
    view = <XDetails setCheckout={setC} setGameCode={setGC}/>
  }

  return (
    <div>
    {view}
    </div>
);
}
