import React from 'react';
import ReactDOM from 'react-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [token, setToken] = React.useState();


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      console.log("returning");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement)
      .then((payload) =>
        setToken(payload.token)
      );

    if (token) {
      let response = await fetch("https://us-central1-party-bison.cloudfunctions.net/completePaymentWithStripe", {
          method: "POST",
          headers: { "Content-Type": "text/plain" },
          body: JSON.stringify({
              amount: 100 * 10 * props.number,
              currency: "usd",
              token: token,
            }),
      });

      if (response.ok) console.log("Purchase Complete!", response)
      response.json()
      .then((res)=>{
          props.success();
      })
    }


  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <br/>
      <Box sx={{display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',}}>
        <Button type="submit" variant="contained" disabled={!stripe || !elements}>
          Pay
        </Button>
      </Box>
    </form>
  );
};

const stripePromise = loadStripe('pk_live_51LTxmmDxTAANC9FUy9Duqd2paM6CgbdoF1f0FMryDHDiMseO1bWqQqENQy7fQLNyINQTdK1vXvNRFkNORUyIJxiZ0067ZWqz5V');

export default function PaymentPortal(props) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm number={props.number} success={props.success}/>
    </Elements>
  );
}
