import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import GuessCard from './guess_card';
import Timer from '../timer.js';
import Box from '@mui/material/Box';


export default function GuessView(props) {
  const [seconds, setSeconds] = React.useState();

  // const setSecs = (x) => {
  //   setSeconds(x);
  // };
  const setSec = (seconds) => {
    setSeconds(seconds);
    return;
  }

  return (
    <Box sx={{ mt: 12 }}>
    <Timer timerRunout={props.timerRunout} startingSeconds={90} setSec={setSec}/>
      <Box sx={{ mt: 2 }}>
        <GuessCard seconds={seconds} setScore={props.setScore} round={props.round} step={props.step} username={props.username} game_type={props.game_type} game_id={props.game_id}/>
      </Box>
    </Box>
  );

}
