import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


export default function Review(props) {
  let cost = 10 * props.number;
  let description = props.number + " people";

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order Summary
      </Typography>
      <List disablePadding>
          <ListItem key={"gif"} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={"Who's gif is it anyways?"} secondary={description} />
            <Typography variant="body2">$10</Typography>
          </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ${cost}
          </Typography>
        </ListItem>
      </List>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={props.handleBack} sx={{ mt: 3, ml: 1 }}>
            Back
          </Button>
          <Button variant="contained"
          onClick={props.handleNext}
          sx={{ mt: 3, ml: 1 }}
          >
            Next
          </Button>

      </Box>

    </React.Fragment>
  );
}
