import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import bison from '../../images/bison.gif';
import GameSummary from './game_summary';
import WinnerMessage from './winner_message.js';
import Slideshow from './slideshow.js'

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


import end_game from './setup_end_game.js';

//const theme = createTheme();

//forward_hedgehog

export default function EndView(props) {
  end_game(props.game_id, props.rounds);

  return (
    <Box sx={{ mt: 12 }}>
      <Box sx={{ mt: 2 }}>
            <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <WinnerMessage game_id={props.game_id}/>
        <Typography component="h1" variant="h6">
          Check your email for details about your win
        </Typography>
      </Box>
      <Box sx={{ width: '100%', mt: 5, backgroundColor: '#fafac4' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                  <Box
                    minHeight="60vh"
                    width="100%"
                    sx={{
                      backgroundImage: 'url('+bison+')',
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                      display: "grid",
                      //placeItems: "center",
                    }}
                  ></Box>
              </Grid>
              <Grid item xs={6}>
                <Slideshow game_id={props.game_id}/>
              </Grid>
            </Grid>
          </Box>
          <Box
      sx={{
        marginTop: 4,
        marginBottom:4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h6">
        Party Bison is a one woman effort, help by <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdar9S8OXCyy-UGLvXFjeRznhrGB2ZD_80Tw8NikGjoWB7x2g/viewform?usp=sf_link">Giving Feedback</Link>
      </Typography>

    </Box>
      </Box>
    </Box>

  );

}
