import {initializeApp} from "firebase/app";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDwQcuYyBDTr718w6QhCV1cYMe-qf-oDP4",
  authDomain: "party-bison.firebaseapp.com",
  databaseURL: "https://party-bison-default-rtdb.firebaseio.com",
  projectId: "party-bison",
  storageBucket: "party-bison.appspot.com",
  messagingSenderId: "580603264916",
  appId: "1:580603264916:web:320de68ee1683f0de31981",
  measurementId: "G-2ZLFWZTR81"
};

const firebase = initializeApp(firebaseConfig);
var storage = getStorage(firebase);

export default storage;
