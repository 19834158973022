import * as React from 'react';

import {useEffect, useState} from "react";
import PopLobby from './pop_lobby';
import GameContainer from './game_container.js'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


// TODO: control the view based on database values, if someone comes in late do something?
export default function PopContainer(props) {

  const [score, setScore] = useState(0);
  const [userList, setUserList] = useState();


  useEffect(() => {
    const scoreref = ref(database, 'games/' + props.game_id +"/users/");
    onValue(scoreref, (snapshot) => {
      const data = snapshot.val();
      setScore(data[props.user_id].score);
      setUserList(Object.keys(data));
    });
    return () => off(scoreref)
  }, []);


  let show;
  if (props.started == false) {
    show = <PopLobby score={score} user_id={props.user_id} game_id={props.game_id} game_type={"pop"}/>
  } else if (props.started == true) {
    show = <GameContainer score={score} users={userList} game_id={props.game_id} username={props.user_id}/>
  } else {
    show = <div>Something went wrong... </div>
  }


  return (
    <div>
      {show}
    </div>
  );

}
