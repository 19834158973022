import * as React from 'react';
import { styled } from '@mui/material/styles';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Preview from './preview.js';

const Input = styled('input')({
  display: 'none',
});

export default function UploadButtons(props) {
  const [image, setImage] = useState();
  const [imageurl, setImageURL] = useState();

  const handleUploadClick = event => {
    if (event.target.files && event.target.files[0]) {
    let img = event.target.files[0];
    setImageURL(URL.createObjectURL(img));
    setImage(img);
    //this.props.updatePicture(URL.createObjectURL(img));props.setPreview("gif", gif);
    props.setPreview("picture", URL.createObjectURL(img));
    props.setI(img);
  }

  };


  return (
    <div>
    <Stack direction="row" alignItems="center" spacing={2}>
      <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={handleUploadClick}/>
        <Button variant="contained" color="secondary" component="span">
          Upload Picture
        </Button>
      </label>
      <label htmlFor="icon-button-file">
        <Input accept="image/*" id="icon-button-file" type="file" />
        <IconButton color="secondary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
    </Stack>
    </div>
  );
}

//  <img className="photo" src={imageurl} />
