
import useMediaQuery from '@mui/material/useMediaQuery';
import LandingBig from './landingbig.js';
import LandingSmall from './landingsmall.js'


function LandingPage(props) {
  const isSScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  let show;
  if (isSScreen) {
    show = <LandingSmall/>
  } else {
    show = <LandingBig/>
  }


  const small = isSScreen ? "small" : "big";

  return (
      <div>
        {show}
      </div>
  );

}

///games/4

export default LandingPage;
