import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import LobbyPlayers from '../lobby_players';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


import setup_pop_game from './setup_pop_game.js';

//const theme = createTheme();

export default function PopLobby(props) {
  // Whether the game has started or is in the lobby
  const [users, setUsers] = useState();
  const [gameId, setGameId] = useState();
  const [disable, setDisabled] = useState(true);

  const setU = (u) => {
    setUsers(u);
    setDisabled(u.length < 3);
  }


  // TODO: If admin, get a button to move forward
  // TODO: check if user already in lobby

  const startGame = () => {
    if (props.game_type=="pop") {
      setup_pop_game(props.game_id, users);
    }

    // flip state in database
    console.log("started");
    set(ref(database, '/valid_games/' + props.game_id), {
      started: true,
      ended: false,
      type: props.game_type,
    });

  };

  return (
    <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <Box sx={{mt:4, ml:4}}>
            <Typography variant="h5">
              #{props.game_id} <br/>
              {props.user_id} <br />
              score: {props.score}
            </Typography>
          </Box>
          </Grid>
        <Grid item xs={12} md={10}>
      <Container component="main" maxWidth="xs">

        <Box
          sx={{
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography component="h1" variant="h5">
            Welcome to Who's gif is it anyways!
          </Typography>
          <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1"> <b>Instructions:</b> Each round you'll be presented with a question like:<i> What was the worst movie ever made?</i>. You'll have a few minutes to answer in visual form (pictures or gifs). You will then guess who submitted which answer (you'll all have different prompts). You score points by correctly guessing the right person! </Typography>
        </Box>
        <Box
          sx={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Players in Lobby:
          </Typography>
          <div>
          <LobbyPlayers game_id={props.game_id} setUsers={setU}/>
          </div>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
            onClick={startGame}
            disabled={disable}
          >
            Start Game
          </Button>
          <Box sx={{mt:2}}>
            At least 3 players must join. Warning: once game starts you cannot add more players.
          </Box>
        </Box>
        </Box>
      </Container>
      </Grid>
    </Grid>
  );
}
