import * as React from 'react';

import {useEffect, useState} from "react";
import database from '../../firebase_database.js';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

export default function GuessPlayers(props) {
  const [players, setPlayers] = React.useState();
  const [show, setShow] = React.useState(true);

  const onTriggerClick = (data, user) => {
    if (user == props.subUser) { //check
      let s = data[user].score + 1;
      set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
        score: s,
        correct: true,
        guessed: user,
        mine: false
      });
      set(ref(database, 'games/' + props.game_id +"/roundquestions/" + props.round + "/" + props.subUser + "/" + props.username), {
        guessed: user,
        correct: true,
        time: props.seconds,
      });
    } else {
      let s = data[user].score;
      set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
        score: s,
        correct: false,
        guessed: user,
        mine: false,
      });
      set(ref(database, 'games/' + props.game_id +"/roundquestions/" + props.round + "/" + props.subUser + "/" + props.username), {
        guessed: user,
        correct: false,
        time: props.seconds,
      });
    }
    setShow(false);

    //Set yourself as Guessed
    set(ref(database, '/games/' + props.game_id + '/guessed/' + props.round + "/" + props.step+ "/" + props.username), {
      guessed: true
    });
  }

  useEffect(() => {
    const uref = ref(database, 'games/' + props.game_id + "/users/");
    onValue(uref, (snapshot) => {
      const data = snapshot.val();
      let players;
      if (props.username != props.subUser) {
        players =  Object.keys(data).map((user, step) => {
              return (
                <Grid item xs={3} key={user}>
                    <Button onClick={() => onTriggerClick(data, user)}>
                      <Typography color="primary" variant="h5">
                      {user}
                      </Typography>
                    </Button>

                </Grid>
                )

            });
          } else {
            players = <Typography>This is your submission... shhhhh stay quiet. </Typography>
            let s = data[props.username].score;
            set(ref(database, 'games/' + props.game_id +"/users/" + props.username), {
              score: s,
              correct: "",
              guessed: "",
              mine: true,
            });
          }

      setPlayers(players);
    });

    return () => off(uref)
  }, []);

  let p;
  if (show) {
    p = <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{mt: 4}}
        >{players}</Grid>
  } else {
    p = <div>Guessed!</div>
  }

  return (
    <div>
      {p}
      </div>
  );
}
