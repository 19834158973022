import * as React from 'react';
import {
  Gif,
} from "@giphy/react-components";

export default function G(props) {

  const onGifClick = (gif, e) => {
    e.preventDefault();
  }

  let g;
  if (props.gif) {
    g = <Gif gif={props.gif} onGifClick={onGifClick} width={200}/>
  } else {
    g = <div> something went wrong.. </div>
  }


  return (
    <div>
      {g}
      </div>
  );
}
