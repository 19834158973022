import React from "react";

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import laughing from '../images/laughing_gif.gif';
import sub from '../images/sub_gif.gif';



import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import database from '../firebase_database.js';

const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function XDetails(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [game, setGame] = React.useState();
  const [copied, setCopied] = React.useState(false);

  function generateGame() {
    console.log("generate game");

    const shortName = uniqueNamesGenerator({
      dictionaries: [adjectives, animals, colors], // colors can be omitted here as not used
      length: 2
    }); // big-donkey
    const gameref = ref(database, '/valid_games/' + shortName);
    onValue(gameref, (snapshot) => {
        const data = snapshot.val();
        console.log(shortName);
        if (data) {
          console.log("name already exists");
          return;
        } else {
          props.setGameCode(shortName);
          // create in DB
          set(ref(database, '/valid_games/' + shortName), {
            started: false,
            ended: false,
            type: "pop",
          });
          setOpen(true);
        }
        // ...
      }, {
        onlyOnce: true
      });

  };
  const copyText = () => {
    navigator.clipboard.writeText(game);
    setCopied(true);
  }

  const gotocheckout = () => {
    generateGame();
    props.setCheckout();
  }

  return (
  <div>

      <Box
        sx={{
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 10,
        }}
      >

        <Typography variant="h2">
          Who's gif is it anyways?
        </Typography>
        <Box sx={{ width: '80vh', height: '50vh',  alignItems: "center"  }}>
            <Box
              height="75%"
              width="75%"
              sx={{
                backgroundImage: 'url('+sub+')',
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                placeItems: "center",
                mt: 5,
              }}
            ></Box>
          </Box>
      <Box width="50%">
        <Typography variant="h6">
        <br/>
        A get-to-know-you game. Test how well you know your teammates in this game designed to surprise, delight, and spark previously unheard stories. A winner will be crowned.
        <br/>
        <Box sx={{ width: '80vh', height: '50vh',  alignItems: "center"  }}>
            <Box
              height="75%"
              width="75%"
              sx={{
                backgroundImage: 'url('+laughing+')',
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                placeItems: "center",
                mt: 5,
              }}
            ></Box>
          </Box>
        <br/>
        <b>Instructions: </b> Each round you'll be presented with a question like: <i>What was the worst movie ever made?</i>. You'll have a few minutes to answer in visual form (pictures or gifs). You will then guess who submitted which answer (you'll all have different prompts). You score points by correctly guessing the right person!
        <br/>
        <br/>
        <br/>
        <b>Cost: </b> This game costs $10 per person. For free games, check out our <Link href="/Icebreakers">Icebreakers</Link>
        </Typography>
        <br/>
        <br/>
        <Button color="primary" variant="contained" onClick={gotocheckout}>
          Create Game
        </Button>
        <br/>
        <br/>
        <br/>
        </Box>
        </Box>

  </div>
);
}
