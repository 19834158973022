import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, {useEffect} from "react";

import {TwitterTweetEmbed} from 'react-twitter-embed';

import TextLoop from './textloop.js'

import bison from '../images/bison.gif';
import laptops from '../images/laptops.jpg';
import laptops_small from '../images/laptops_small.jpg';
import team from '../images/team.jpg';
import team_small from '../images/team_small.jpg';
// import TextLoop from "react-text-loop";
import './landing.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CakeIcon from '@mui/icons-material/Cake';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import GameShell from '../game_shell.js';

import { BackgroundImage } from 'react-image-and-background-image-fade';
import {Route, Routes} from 'react-router-dom';


function LandingBig(props) {
  const [laptopsPic, setLaptopsPic] = React.useState(laptops_small);
  const [loadingLaptops, setLoadingLaptops] = React.useState(true);
  const [loadingTeam, setLoadingTeam] = React.useState(true);
  const [teamPic, setTeamPic] = React.useState(team_small);

  useEffect(() => {

    // start loading original laptops image
    const imageToLoad = new Image();
    imageToLoad.src = laptops;
    imageToLoad.onload = () =>
      // When image is loaded replace the image's src and set loading to false
      setLaptopsPic(laptops);
      setLoadingLaptops(false);

    // start loading original team image
    const imageToLoad2 = new Image();
    imageToLoad2.src = team;
    imageToLoad2.onload = () =>
      // When image is loaded replace the image's src and set loading to false
      setTeamPic(team);
      setLoadingTeam(false);
  });

  return (
    <div>
    <Box sx={{ }}>
    <Box sx={{ width: '100%',  backgroundColor: '#fafac4'}}>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

            <Grid item xs={12} md={6}>
            <Box sx={{ ml: 5, mt: 3, display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'}} >

              <Typography variant="h1">
              Your next team event should be
              <Typography variant="h1" color="primary">
                <TextLoop/>
                </Typography>
              </Typography>

              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
            <Box sx={{
                width: '80vh',
                height:'70vh',
                alignItems: "center"
              }}>
                <Box
                  height="100%"
                  width="100%"
                  sx={{
                    backgroundImage: 'url('+bison+')',
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",
                    mt: 5,
                  }}
                ></Box>
              </Box>


            </Grid>
          </Grid>
        </Box>
        </Box>
        <Divider/>

        <Divider/>
        <BackgroundImage
                src={laptopsPic}
                style={{
                  opacity: loadingLaptops ? 0.5 : 1,
                  transition: "opacity .15s linear",
                  minHeight: '100vh',
                  minwidth: '100vh',
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  display: "grid",
                  placeItems: "center",
                }}
              >
            <Box  sx={{mt: 5, backgroundColor: '#fbfbc9' }}>
            <Box sx={{  mt: 5, ml: 5, mr: 5, mb: 5}}>
            <Typography variant="h5">
            Remote teams are teams. <br/><br/> They should feel like it. <br/> <br/>
            People work better together when they feel like they know each other. <br/> <br/>
            In a remote-first world, it's even more important for your team to kick back and take a break.
            </Typography>
            </Box>
            </Box>
          </BackgroundImage>


        <Divider/>
        <Box
          sx={{
            mt: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Typography variant="h4">
        Any of these sound familiar?
        </Typography>
        <List sx={{mb: 10}}>
          <ListItem>
            <ListItemIcon>
                <ArrowRightAltIcon />
            </ListItemIcon>
            <ListItemText
              className="listitem"
              primary="Virtual team events feel more like work than play"
              sx={{ display: 'inline' }}
            />
          </ListItem>
        <ListItem>
          <ListItemIcon>
              <ArrowRightAltIcon />
          </ListItemIcon>
              <ListItemText
                className="listitem"
                primary="One conversation thread dominates the entire experience"
              />
          </ListItem>

          <ListItem>
            <ListItemIcon>
                <ArrowRightAltIcon />
            </ListItemIcon>
                <ListItemText
                  className="listitem"
                  primary="You walk away knowing very little about your teammates"
                />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                  <ArrowRightAltIcon />
              </ListItemIcon>
                  <ListItemText
                    className="listitem"
                    primary="Planning a team event is overwhelming and no one takes the initiative"
                  />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                    <ArrowRightAltIcon />
                </ListItemIcon>
                    <ListItemText
                      primary="MCing a team event prevents you from having any fun"
                      className="listitem"
                    />
                </ListItem>

          </List>

          <BackgroundImage
                  src={teamPic}
                  width="100%"
                  style={{
                    opacity: loadingTeam ? 0.5 : 1,
                    transition: "opacity .15s linear",
                    backgroundSize: "cover",
                    minHeight: '100vh',
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >


          <Box sx={{  mt: 10, ml: 5, mr: 5, mb: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'}}>
          <Typography variant="h2" color="primary">
           There's a better way
          </Typography>
          <Typography variant="h5" color="secondary">
            Party Bison has designed a set of games, perfect for virtual teams.
          </Typography>
          </Box>

          </BackgroundImage>

            <Box sx={{mt: 10}}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

              <Grid item xs={6}>
              <Link
                href="Icebreakers"
                sx={{ my: 1, mx: 1.5 }}
                underline="hover"
              >
                <Typography variant="h4" color="primary">
                Icebreakers

                </Typography>
                </Link>
                <List>
                  <ListItem>
                    <ListItemIcon>
                        <AcUnitIcon />
                    </ListItemIcon>
                        <ListItemText
                          primary="100% Free"
                          className="listitem"
                        />
                  </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <AcUnitIcon />
                  </ListItemIcon>
                      <ListItemText
                        primary="1-15 Players"
                        className="listitem"
                      />
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                        <AcUnitIcon />
                    </ListItemIcon>
                        <ListItemText
                          primary="Play for 5-50 minutes"
                          className="listitem"
                        />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                          <AcUnitIcon />
                      </ListItemIcon>
                          <ListItemText
                            primary="Break the ice and warm your team up"
                            className="listitem"
                          />
                      </ListItem>

                  </List>
              </Grid>
              <Grid item xs={6}>
              <Link

                href="SignatureEvents"
                sx={{ my: 1, mx: 1.5 }}
                underline="hover"
              >
              <Typography variant="h4" color="primary">
                 Signature Events
              </Typography>
              </Link>
                <List>
                  <ListItem>
                    <ListItemIcon>
                        <CakeIcon />
                    </ListItemIcon>
                        <ListItemText
                          primary="Play with your team and win prizes"
                          className="listitem"
                        />
                  </ListItem>
                <ListItem>
                  <ListItemIcon>
                      <CakeIcon />
                  </ListItemIcon>
                      <ListItemText
                        primary="30-90 minutes of dedicated time"
                        className="listitem"
                      />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                        <CakeIcon />
                    </ListItemIcon>
                        <ListItemText
                          primary="Challenges your team to get to know each other better"
                          className="listitem"
                        />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                          <CakeIcon />
                      </ListItemIcon>
                          <ListItemText
                            primary="3-10 Players"
                            className="listitem"
                          />
                      </ListItem>

                  </List>
            </Grid>
            </Grid>
            </Box>
            <Box minHeight="40vh" sx={{ width: '50%', mt: 5, mb: 10, backgroundColor: '#ccd3d4' }}>

              <Box sx={{  mt: 7, ml: 5, mr: 5, mb: 10}}>
              <Box sx={{mb: 7}}>
                <Typography variant="h4">
                  See what people are saying about us:
                </Typography>
              </Box>
              <TwitterTweetEmbed
              tweetId={'1559974599969984517'}
            />

              </Box>
              <Box sx={{  mt: 10, ml: 5, mr: 5, mb: 10}}>
            <TwitterTweetEmbed
            tweetId={'1559980509777735680'}
          />

              </Box>
              <Box sx={{  mt: 10, ml: 5, mr: 5, mb: 10}}>
            <TwitterTweetEmbed
            tweetId={'1560029669595955201'}
          />

              </Box>
            </Box>
            <Box sx={{mb: 10}}>
            <Button href="form" color="primary" size="large" variant="contained" sx={{ my: 1, mx: 1.5}}>
              Join Existing Game Now
            </Button>
            </Box>
          </Box>
          <footer>
          <Box
            sx={{
              m2: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
          <Box>
            Made with ♥ by Party Bison
            </Box>
            <br/>
            <Box>
            Party Bison is a one woman effort, help by <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdar9S8OXCyy-UGLvXFjeRznhrGB2ZD_80Tw8NikGjoWB7x2g/viewform?usp=sf_link">Giving Feedback</Link>

            </Box>
          </Box>
        </footer>
    </div>
  );

}

///games/4
//        <Box
        //   minHeight="100vh"
        //   minwidth="100vh"
        //   sx={{
        //     backgroundSize: "cover",
        //     backgroundPosition: "top",
        //     display: "grid",
        //     placeItems: "center",
        //   }}
        // >
        //
        // <BackgroundImage
        //         src={laptops}
        //         isResponsive
        //         lazyLoad
        //         style={{
        //           minHeight: '100vh',
        //           minwidth: '100vh',
        //           backgroundSize: "cover",
        //           backgroundPosition: "top",
        //           display: "grid",
        //           placeItems: "center",
        //         }}
        //       >


        // <Box
        //   minHeight="100vh"
        //   width="100%"
        //   sx={{
        //     backgroundImage: 'url('+team+')',
        //     backgroundSize: "cover",
        //     backgroundPosition: "top",
        //     display: "grid",
        //     mt: 10,
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //   }}
        // >

        // 
        // //        <Box sx={{mt: 5, mb:5, display: "grid",
        //         placeItems: "center",}}>
        //         <Button href="https://docs.google.com/forms/d/e/1FAIpQLSdGr9UjCss_xd46X1PgbvCnnomBrrPl0K64nHOtwgRvIF1oOg/viewform?usp=sf_link" color="primary" size="large" variant="contained">COMING AUGUST 27, SIGN UP TO GET NOTIFIED</Button>
        //         </Box>

export default LandingBig;
