import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DetailsForm from './DetailsForm';
import PaymentPortal from './PaymentPortal';
import Review from './Review';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


const steps = ['Details', 'Review your order', 'Payment details'];

const theme = createTheme();

export default function Checkout(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [number, setNumber] = React.useState(3);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState();

  const setFN = (name) => {
    setFirstName(name);
  };

  const setLN = (name) => {
    setLastName(name);
  };

  const setE = (email) => {
    setEmail(email);
  };

  const setN = (num) => {
    setNumber(num);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const success = () => {
    setActiveStep(activeStep + 1);
    sendEmail();
    // TODO: send details to DB for tracking
    let cost = 10*number;
    set(ref(database, 'bought_games/' + props.gameCode ), {
      email: email,
      firstName: firstName,
      lastName: lastName,
      gameCode: props.gameCode,
      gameName: "Who's gif is it anyways?",
      players: number,
      cost: cost,
    });
  };

  const sendEmail = async () => {
      let cost = 10*number;
      let response = await fetch("https://us-central1-party-bison.cloudfunctions.net/sendMail", {
          method: "POST",
          headers: { "Content-Type": "text/plain" },
          body: JSON.stringify({
              destination: email,
              firstName: firstName,
              lastName: lastName,
              gameCode: props.gameCode,
              gameName: "Who's gif is it anyways?",
              players: number,
              cost: cost,
            }),
      });

      if (response.ok) console.log("Purchase Complete!", response)
      response.json()
      .then((res)=>{
          console.log("now this happens");
      })

  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <DetailsForm handleNext={handleNext} setNumber={setN} setLastName={setLN} setFirstName={setFN} setEmail={setE}/>;
      case 1:
        return <Review number={number} handleNext={handleNext} handleBack={handleBack}/>;
      case 2:
        return <PaymentPortal number={number} success={success}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <ThemeProvider theme={theme}>

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you!
                </Typography>
                <Typography variant="subtitle1">
                  Your game code is <b>{props.gameCode}</b>, we have emailed you your game code and reciept.
                  You and your team can enter the game lobby by going to partybison.com/join or clicking Join Game Now at the top of your screen.
                  You can start your game from the lobby. You can do this at any time, but once started you will not be able to add new players.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}

              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

//
// <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
//   {activeStep !== 0 && (
//     <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
//       Back
//     </Button>
//   )}
//   {activeStep !== steps.length - 1 && (
//     <Button variant="contained"
//     onClick={handleNext}
//     disabled={disabled}
//     sx={{ mt: 3, ml: 1 }}
//     >
//       Next
//     </Button>
//   )}
//
//
// </Box>
