import paparazzi_bison from '../images/paparazzi_bison.png';
import paparazzi_bison_small from '../images/paparazzi_bison_small.png';
import './signature.css';
import { styled } from '@mui/material/styles';

import CakeIcon from '@mui/icons-material/Cake';
import BottomNavigation from '@mui/material/BottomNavigation';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Link from '@mui/material/Link';

import giphy from '../images/GIPHY_Logo.png';

import ProgressiveImage from '../ProgressiveImage.js';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function SignatureX(props) {

  return (
    <Box sx={{ mt: 6}}>
          <Paper
          sx={{
            p: 2,
            margin: 'auto',

            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <ButtonBase sx={{  maxWidth: '100%',
                maxHeight: '100%',}}>
                <ProgressiveImage
                  src={paparazzi_bison}
                  placeholder={paparazzi_bison_small}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="h4" component="div">
                    Who's gif is it anyways?
                  </Typography>

                  <Typography variant="body" gutterBottom>
                    A get-to-know-you game. Test how well you know your teammates in this game designed to surprise, delight, and spark previously unheard stories. A winner will be crowned.
                  </Typography>
                  <Box sx={{mt: 3, mb:3}}>
                  <Typography variant="body" color="text.secondary">

                      <Box sx={{ ml: 6, mt:1}}>
                      <CakeIcon className="listitem2"/> A loud and riotous game <br/>
                      <CakeIcon className="listitem2"/> For 3-10 players  <br/>
                      <CakeIcon className="listitem2"/> Takes 40-60 minutes to complete <br/>
                      </Box>
                  </Typography>
                  </Box>
                  <Typography variant="body" gutterBottom>
                    <b>Instructions: </b> Each round you'll be presented with a question like: <i>What was the worst movie ever made?</i>. You'll have a few minutes to answer in visual form (pictures or gifs). You will then guess who submitted which answer (you'll all have different prompts). You score points by correctly guessing the right person!
                  </Typography>


                </Grid>
                <Grid item>
                <Link
                  variant="button"
                  color="primary"
                  href="/LearnMore"
                  sx={{ my: 1, mx: 1.5 }}

                >
                  Learn More
                </Link>

                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ mr: 3}}>
                  <Typography variant="subtitle1" component="div" color="#85bb65">
                    <b>$10 per person</b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
    </Box>
  );

}
