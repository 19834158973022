import * as React from 'react';

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useEffect, useState} from "react";
import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";

import Typography from '@mui/material/Typography';

//const theme = createTheme();

export default function SubmissionSidebar(props) {
  const [players, setPlayers] = useState();

  console.log(props.users);

  useEffect(() => {
    const uref = ref(database, '/games/' + props.game_id + '/submitted/' + props.round + '/' + props.step);
    onValue(uref, (snapshot) => {
      const data = snapshot.val();
      const players =  Object.keys(data).map((user, step) => {
            if (data[user]['submitted'] = true) {
              return (
                  <div key={user}>
                    {user}
                  </div>
                )
              }
            });
      setPlayers(players);
      if (players.length == props.users.length) {
        props.everyoneSubmitted();
      }
    });

    return () => off(uref)
  }, []);

  return (
    <div>
      <Typography variant="h5">
        Submitted
      </Typography>
      <Typography>
        {players}
      </Typography>
    </div>
  );
}
