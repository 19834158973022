import * as React from 'react';
import {useEffect, useState} from "react";

import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";


import Typography from '@mui/material/Typography';

export default function WinnerMessage(props) {
  const [name, setName] = React.useState();

  useEffect(() => { /// maybe you don't need this?
    const uref = ref(database, 'games/' + props.game_id +"/winner/");
    onValue(uref, (snapshot) => {
      const data = snapshot.val();
      setName(data.username);
    });
    return () => off(uref)
  }, []);

  return (
    <Typography component="h1" variant="h2">
      🎉 Congrats {name}!! 🎉
    </Typography>
  );
}
