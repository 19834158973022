import { useState, useEffect } from "react";

const loopArr = [
  "fun",
  "refreshing",
  "unifying",
  "🎉 a party 🎉",
];

export default function TextLoop() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalDelayMilliseconds = loopArr[index].length * 500;
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        // reset index if current index is greater than array size
        return prevIndex + 1 < loopArr.length ? prevIndex + 1 : 0;
      });
    }, intervalDelayMilliseconds);

    return () => clearInterval(interval);
  });

  return (
    <div className="App">
        <div key={loopArr[index]}>{loopArr[index]}</div>
    </div>
  );
}
