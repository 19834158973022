import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';

import puzzle_bison from '../images/puzzle_bison.png';
import puzzle_bison_small from '../images/puzzle_bison_small.png';


import { styled } from '@mui/material/styles';


import AcUnitIcon from '@mui/icons-material/AcUnit';
import BottomNavigation from '@mui/material/BottomNavigation';

import ProgressiveImage from '../ProgressiveImage.js';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function IcebreakerPuzzle(props) {

  const gen = () => {
    props.generateGame("puzzle");
  }

    return (
      <Box sx={{ mt: 6}}>
            <Paper
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ButtonBase sx={{  maxWidth: '100%',
                  maxHeight: '100%',}}>
                  <ProgressiveImage
                    src={puzzle_bison}
                    placeholder={puzzle_bison_small}
                  />
                </ButtonBase>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="h4" component="div">
                      Mini Puzzle Hunt
                    </Typography>
                    <Typography variant="body" gutterBottom>
                      Compete to solve a series of increasingly difficult riddles, puzzles, and brain teasers.
                    </Typography>
                    <Box sx={{mt:5}}>
                    <Typography variant="body" color="text.secondary">
                      This Icebreaker is: <br/>
                        <Box sx={{ ml: 6, mt:1}}>
                        <AcUnitIcon className="listitem2"/> Best for native english speakers <br/>
                        <AcUnitIcon className="listitem2"/> A quiet problem solving game <br/>
                        <AcUnitIcon className="listitem2"/> For one to fifteen players  <br/>
                        <AcUnitIcon className="listitem2"/> Useful to warm your brain up  <br/>
                        </Box>
                    </Typography>
                    </Box>

                  </Grid>
                  <Grid item>
                    <Button color="primary" onClick={gen} disabled>
                      Coming Soon
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box sx={{ mr: 3}}>
                  <Typography variant="subtitle1" component="div" color="#85bb65">
                    <b>Free</b>
                  </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
      </Box>
    );



}
