import * as React from 'react';

import {useEffect, useState} from "react";
import database from '../../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import PicPreview from './pic_preview.js';
import GifPreview from './gif_preview.js';
import Grid from '@mui/material/Grid';


export default function GuessPreviewContainer(props) {
  const [type, setType] = React.useState();
  const [url, setUrl] = React.useState();


  useEffect(() => {
    const subref = ref(database, 'games/' + props.game_id + "/roundquestions/" + props.round + "/" + props.subUser);

    onValue(subref, (snapshot) => {
      const data = snapshot.val();
      setType(data.type);
      setUrl(data.url);
    });

    return () => off(subref)
  }, []);


  let p;
  let message;
  if (type == "gif") {
    p = <GifPreview type={type} thing={url} game_id={props.game_id} round={props.round} subUser={props.subUser}/>
  } else if (type == "picture") {
    p = <PicPreview type={type} game_id={props.game_id} round={props.round} subUser={props.subUser}/>
  } else {
    p = <PicPreview type={"blank"} game_id={props.game_id} round={props.round} subUser={props.subUser}/>
    message = <div>Someone forgot to submit....</div>
  }


  return (
    <Grid container item  xs={12} lg={7} justifyContent="center" alignItems="flex-start" mx="auto">
    {p}
    {message}
    </Grid>
  );

}
