import React from 'react';
import ReactDOM from 'react-dom';
import {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Checkout from './Checkout/Checkout.js';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';

import IconButton from '@mui/material/IconButton';
import LandingPage from './Landing/landingpage.js';

import Link from '@mui/material/Link';
import {Routes, Route} from 'react-router-dom';
import Icebreakers from './Icebreakers/icebreakers.js';
import Signature from './Signature/signature.js';
import LearnMore from './Signature/x_learn_more.js';
import GameShell from './game_shell';

import LobbyForm from './Lobby/lobby_form.js';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import database from './firebase_database.js';

import useMediaQuery from '@mui/material/useMediaQuery';

const pages = ['Icebreakers', 'Signature Events', 'Join Game'];

const stripePromise = loadStripe('pk_live_51LTxmmDxTAANC9FUy9Duqd2paM6CgbdoF1f0FMryDHDiMseO1bWqQqENQy7fQLNyINQTdK1vXvNRFkNORUyIJxiZ0067ZWqz5V');


function App() {
  const [page, setPage] = useState("landing");
  const [gameId, setGameId] = useState();
  const [started, setStarted] = useState();
  const [gameType, setGameType] = useState();
  const [username, setUsername] = useState();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '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',
  // };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
    const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    const gameref = ref(database, '/valid_games/' + gameId);
      onValue(gameref, (snapshot) => {
          const data = snapshot.val();
          if (data && data.started) {
            setStarted(true);
            if (page == "lobby") {
              setPage("game");
            }
          }
          // ...
        });
    return () => off(gameref)
  }, []);

  function gameOrLobby(game_id) {
    //If game started, go to game, else go to lobby
    const gameref = ref(database, '/valid_games/' + game_id);
      onValue(gameref, (snapshot) => {
          const data = snapshot.val();
          if (data && data.started) {
            setStarted(true);
            setPage("game");

          } else {
            setPage("lobby");
          }
          // ...
      });
  };

  function setUser(user) {
    setUsername(user);
  }

  function goToLanding() {
    setPage("landing");
  };

  function goToLobby() {
    setPage("lobby");
  }

  function goToGame() {
    setPage("game");
  }

  function changeGameId(game_id) {
    setGameId(game_id);
    //setGameId("ok_snail");
  }

  function setType(type) {
    setGameType(type);
  }

  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const isSScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  let nav;
  if (isSScreen) {
    nav = <Toolbar sx={{ flexWrap: 'wrap' }}>

      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
      <MenuItem key={"Home"} onClick={handleCloseNavMenu}>
        <Link
          color="text.primary"
          href="/"
          sx={{ my: 1, mx: 1.5 }}
          underline="none"
        >
        <Typography textAlign="center">Home</Typography>
        </Link>
      </MenuItem>
      <MenuItem key={"Icebreakers"} onClick={handleCloseNavMenu}>
        <Link
          color="text.primary"
          href="/Icebreakers"
          sx={{ my: 1, mx: 1.5 }}
          underline="none"
        >
        <Typography textAlign="center">Icebreakers</Typography>
        </Link>
      </MenuItem>
      <MenuItem key={"Icebreakers"} onClick={handleCloseNavMenu}>
        <Link
          color="text.primary"
          href="/SignatureEvents"
          sx={{ my: 1, mx: 1.5 }}
          underline="none"
        >
        <Typography textAlign="center">Signature Events</Typography>
        </Link>
      </MenuItem>
      <MenuItem key={"Icebreakers"} onClick={handleCloseNavMenu}>
        <Link
          color="text.primary"
          href="/form"
          sx={{ my: 1, mx: 1.5 }}
          underline="none"
        >
        <Typography textAlign="center">Join Game</Typography>
        </Link>
      </MenuItem>
      </Menu>

              <Link
                color="text.primary"
                href="/"
                sx={{ my: 1, mx: 1.5 }}
                underline="none"
              >
              <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                &#127881; &#128003; Party Bison &#127881; &#128003;
              </Typography>
              </Link>
          </Toolbar>
  } else {
    nav = <Toolbar sx={{ flexWrap: 'wrap' }}>
      <Link
        color="text.primary"
        href="/"
        sx={{ my: 1, mx: 1.5 }}
        underline="none"
      >
      <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
        &#127881; &#128003; &#127881; &#128003; Party Bison &#127881; &#128003; &#127881; &#128003;
      </Typography>
      </Link>
      <nav>
        <Link
          variant="button"
          color="text.primary"
          href="/Icebreakers"
          sx={{ my: 1, mx: 1.5 }}
        >
          Icebreakers
        </Link>
        <Link
          variant="button"
          color="text.primary"
          href="/SignatureEvents"
          sx={{ my: 1, mx: 1.5 }}
        >
          Signature Events
        </Link>
      </nav>
      <Button href="#" color="primary" variant="contained" sx={{ my: 1, mx: 1.5 }}>
      <Link
        variant="button"
        href="/form"
        color="text.primary"
        underline="none"
      >
        Join Game Now
      </Link>
      </Button>
    </Toolbar>
  }


  return (
      <div className="App">

      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        {nav}
      </AppBar>
      <Routes>
        <Route path='/' element={<LandingPage/>}></Route>
        <Route path='icebreakers' element={<Icebreakers/>}></Route>
        <Route path='signatureevents' element={<Signature/>}></Route>
        <Route path='gameshell' element={<GameShell/>}></Route>
        <Route path="/games/:gameId/:userId" element={<GameShell />} />
        <Route path='form' element={<LobbyForm/>}/>
        <Route path='checkout' element={<Checkout/>}/>
        <Route path='LearnMore' element={<LearnMore/>}/>
        <Route path='join' element={<LobbyForm/>}/>
      </Routes>
      </div>
  );
}


export default App;


// TODO: wrap stripe elememt around everything
// <Elements stripe={stripePromise} options={options}>
