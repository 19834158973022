import * as React from 'react';
import {
  Gif,
} from "@giphy/react-components";


import Box from '@mui/material/Box';
import '../image.css';

export default function Preview(props) {

  const onGifClick = (gif, e) => {
    e.preventDefault();
  }

  let preview;
  if (props.type == "gif" && props.thing) {
    preview = <Gif gif={props.thing} onGifClick={onGifClick}/>
  } else if (props.type == "picture" && props.thing) {
    preview = <img className="photo" src={props.thing}/>
  } else {
    preview = <img className="photo" align="center"  />
  }



  return (
    <Box sx={{ mt: 3, mb: 3 }}>
    {preview}
    </Box>
  );
}
