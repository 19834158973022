let qa = {
  1: {
    question: "As I was going to St. Ives, I met a man with seven wives. Every wife had seven sacks, and every sack had seven cats. Every cat had seven kits. Kittens, cats, sacks, and wives, How many were going to St. Ives?",
    answer: "1",
  },
  0: {
    question: "Men equipped with blades and sticks, sometimes masked, keep chasing me. I wish I weren’t in this fix, but that’s my cold reality. My skin is tough and far from pale, and, though I am not in control, the water’s smooth on which I sail, gliding onward to my goal.",
    answer: "hockey puck",
  },
  2: {
    question: "Hand in hand we walk together. Through the park, I guide my boy. I’m as carefree as a feather, filled with laughter, filled with joy. He’s so glad, he gets excited, lets me go and looks around. Sad to say, he’s not delighted. He won’t find me on the ground.",
    answer: "balloon",
  },
  3: {
    question: "On sunny days I’m fast asleep, with little cause to leave my bed. A back-and-forth patrol I’ll keep. When that first droplet hits my head. Should nature fail to stimulate my efforts of its own volition, pounding rains you’ll simulate and I’ll complete my crucial mission.",
    answer: "windshield wiper",
  },
  4: {
      question: "This stadium’s a wall of sound, a place that thunder calls its home. It’s set for concerts in the round, its walls linked by a flattened dome. Around the amphitheater’s rim, a silver-wearing throng applauds. While the roof shakes with a hymn, of pounding beats to rouse the gods.",
      answer: "tambourine",
    },

  5: {
      question: "Greetings! May I have this dance? Let’s have a bite, and then a drink. I’m sure you’ll like my bold advance, no matter what your beau may think. You’ll hardly need to check a mirror, to see my handsome patriarchal smile, as it could not be clearer. (But you’ll never see me sparkle!)",
      answer: "count dracula",
    },
};

export default qa;
