import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

const Timer = (props) => {
  const [seconds, setSeconds] = useState(props.startingSeconds);
  const [timeLeft, setTimeLeft] = useState({minutes: 5, seconds:0});
  const [isActive, setIsActive] = useState(true);

  function toggle() {
    setIsActive(!isActive);
  }

  function reset() {
    setSeconds(300);
    setIsActive(false);
  }

  const calculateTimeLeft = () => {

    let tLeft = {};

    if (seconds > 0) {
      tLeft = {
        minutes: Math.floor((seconds  / 60) % 60),
        seconds: Math.floor((seconds) % 60)
      };
    }
    return tLeft;
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    }
    setTimeLeft(calculateTimeLeft);
    if (seconds == 0) {
      props.timerRunout();
    }
    props.setSec(seconds);
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return (
    <div className="app">
      <div className="time" align="center">
        <Typography component="h1" variant="h5">
          {timeLeft.minutes} minutes : {timeLeft.seconds} seconds
        </Typography>
      </div>
      <div className="row">
      </div>
    </div>
  );
};

export default Timer;
