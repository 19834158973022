import React from "react";
import SignatureX from './signature_x_card.js';
import SignatureMurder from './signature_murder_card.js';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import useMediaQuery from '@mui/material/useMediaQuery';

const small_screen = {
  mr: 3,
  ml: 3,
};

const large_screen = {
  mr: 15,
  ml: 15,
};


export default function Signature(props) {
  const isSScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  let screen_style;
  if (isSScreen) {
    screen_style = small_screen;
  } else{
    screen_style = large_screen;
  }

  return (
  <div>
    <Box sx={{ mt: 6, mb:10, ...screen_style  }}>
    <Box sx={{ width: '100%', mb:10 }}>
      <Typography variant="h4">
      Signature Bison Events 🎉 🐃
      </Typography>
      <Typography variant="h6">
        Dive in to experience games built to challenge your team and bring you closer together. Best when you have at least an hour of dedicated time for team bonding.
      </Typography>
    </Box>
    <Divider/>
    <SignatureX/>
    <SignatureMurder/>
    </Box>
  </div>
  );
}
