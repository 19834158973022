import * as React from 'react';

import {useEffect, useState} from "react";
import PuzzleLobby from './puzzle_lobby';
import PuzzleGameContainer from './game_container.js'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Particles from "react-tsparticles";
import particleConfig from "./particlesjs-config.json";
import './bubble.css';


// TODO: control the view based on database values, if someone comes in late do something?
export default function Puzzle(props) {

  let show;
  if (props.started == false) {
    show = <PuzzleLobby game_id={props.game_id} game_type={"puzzle"}/>
  } else if (props.started == true) {
    show = <PuzzleGameContainer game_id={props.game_id} username={props.user_id}/>
  } else {
    show = <div>Something went wrong... </div>
  }


  return (
    <div className={"container-style"} >
    <Particles
        style={{
          width: "100%",
          minHeight: "100vh",
          height: "100%",
          position: "absolute"
        }}
        params={particleConfig}
      />
      <div id="bounce1">
        <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Box sx={{mt:4, ml:4}}>
                <Typography variant="h5">
                  #{props.game_id} <br/>
                  {props.user_id} <br />
                </Typography>
              </Box>
              </Grid>
            <Grid item xs={12} md={8}>
            {show}
            </Grid>
          </Grid>
          </div>

    </div>
  );

}
