import * as React from 'react';

import '../image.css';

import {useEffect, useState} from "react";

import {getStorage, uploadBytes, getDownloadURL} from "firebase/storage";
import {ref as storageref} from "firebase/storage";
import storage from '../../firebase_storage.js';
import Box from '@mui/material/Box';

import { Image } from 'react-image-and-background-image-fade';

export default function PicPreview(props) {
  const [url, setUrl] = React.useState(props.url);


  useEffect(() => {
    if (props.type == "picture") {
    const imagesref = storageref(storage, 'games/' + props.game_id + "/" + props.round + "/" + props.subUser);
    getDownloadURL(imagesref).then((url) => {
        setUrl(url);
      });
    } else if (props.type == "blank") {
      // pick random number
      let index = Math.floor(Math.random() * (14 - 1) + 1);
      const imagesref = storageref(storage, 'stock_bison/' + index + '.png');
      getDownloadURL(imagesref).then((url) => {
          setUrl(url);
        });
    }

      return () => {}
    }, []);

  return (
    <Box sx={{ mt: 3, mb: 3 }}>
    <Image
            src={url}
            style={{
              transition: "opacity .15s linear",
              maxnHeight: '50vh',
              maxwidth: '50vh',
              placeItems: "center",
            }}
          />
    </Box>
  );
}
