import * as React from 'react';

import {useEffect, useState} from "react";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

// TODO: control the view based on database values, if someone comes in late do something?
export default function ChoseQuestion(props) {
  const [category, setCategory] = useState();
  const [buttons, setButtons] = useState();
  const [qs, setQs] = useState();
  const [showCat, setShowCat] = useState(true);


  const clickCat = (cat) => {
    setCategory(cat);
    if (cat != "wildcard") {
      setQs(props.questions[cat]);
    }
    setShowCat(false);
  }

  const clickQ = (q) => {
    props.setQuestion(q, category);
    setShowCat(true);
    props.handleClose();
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let question = data.get('question');
    console.log(question);
    props.setQuestion(question, category);
    setShowCat(true);
    props.handleClose();
  }


  // we need to pull a category, pull three Questions
  // pick a hotseat person
  // pick a spotlight person
  // present spotlight person with Questions
  // display question picked on screen... say hotseat person and spotlight person must answers

  let show;
  if (showCat && props.open) {
    show = props.categoryChoices.map((cat, step) => {
          return (
            <Grid item align="center" key={cat}>
                <Button onClick={() => clickCat(cat)}>
                <Typography color="text.secondary" variant="h5">
                {cat}
                </Typography>
                </Button>
            </Grid>
            )
        });
    show.push(
      <Grid item align="center" key={"Your choice"}>
          <Button onClick={() => clickCat("wildcard")}>
          <Typography color="text.secondary" variant="h5">
            Your choice
          </Typography>
          </Button>
      </Grid>
    );
  } else if (props.open) {
    if (category == "wildcard") {
      show =
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5 }}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField id="question" name="question" label="Your question" variant="outlined" autofocus/>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="secondary"
          >Submit</Button>
        </FormControl>
      </Box>
    } else {
      show = qs.map((q, step) => {
            return (
              <Grid item key={q}>
                  <Button onClick={() => clickQ(q)}>
                  <Typography color="text.secondary" variant="h5">
                  {q}
                  </Typography></Button>
              </Grid>
              )
          });
    }
  } else {
    show = <div> something went wrong </div>
  }


  return (
      <div id="bounce1">
        <Modal
          open={props.open}

          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
        <Box sx={{ ...style, width: 400 }}>
          {show}
          </Box>
        </Modal>

      </div>
  );
}
