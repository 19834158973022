import './slideshow.css';
import * as React from 'react';
import GameSummary from './game_summary';

const types = ["easiest", "hardest", "contentious"]
const delay = 2500;

export default function Slideshow(props) {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === types.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {types.map((type, index) => (
          <div
            className="slide"
            key={index}
          ><GameSummary game_id={props.game_id} type={type}/></div>
        ))}
      </div>

    </div>
  );
}
