
import React, { useCallback, useEffect, useRef, useState } from "react";



import IcebreakerPuzzle from './icebreaker_puzzle.js';
import IcebreakerHotseat from './icebreaker_hotseat.js';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import useMediaQuery from '@mui/material/useMediaQuery';

import {getDatabase, ref, set, onValue, off, push} from "firebase/database";
import database from '../firebase_database.js';

const { uniqueNamesGenerator, adjectives, colors, animals } = require('unique-names-generator');

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const small_screen = {
  mr: 3,
  ml: 3,
};

const large_screen = {
  mr: 15,
  ml: 15,
};



export default function Icebreakers(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [game, setGame] = React.useState();
  const [copied, setCopied] = React.useState(false);


  function generateGame(type) {
    console.log("generate game");
    console.log(type);

    const shortName = uniqueNamesGenerator({
      dictionaries: [adjectives, animals, colors], // colors can be omitted here as not used
      length: 2
    }); // big-donkey
    const gameref = ref(database, '/valid_games/' + shortName);
    onValue(gameref, (snapshot) => {
        const data = snapshot.val();
        console.log(shortName);
        if (data) {
          console.log("name already exists");
          return;
        } else {
          setGame(shortName);
          // create in DB
          set(ref(database, '/valid_games/' + shortName), {
            started: false,
            ended: false,
            type: type,
          });
          setOpen(true);
        }
        // ...
      }, {
        onlyOnce: true
      });

  };
  const copyText = () => {
    navigator.clipboard.writeText(game);
    setCopied(true);
  }

  const isSScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  let screen_style;
  if (isSScreen) {
    screen_style = small_screen;
  } else{
    screen_style = large_screen;
  }

  return (
  <div>

    <Box sx={{ mt: 6, mb:10, ...screen_style  }}>
    <Box sx={{ width: '100%', mb:10 }}>
      <Typography variant="h4">
      Icebreakers 🧊☄️
      </Typography>
      <Typography variant="h6">
        Break the ice and warm your team up. These games are simple and flexible. Spend five minutes in a one-on-one or an hour with a big group. Always free and easy.
      </Typography>
    </Box>
    <Divider/>
    <IcebreakerHotseat generateGame={generateGame}/>
    <IcebreakerPuzzle generateGame={generateGame}/>
    </Box>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
    <Box sx={{ ...style, width: 400 }}>
      <h2 id="parent-modal-title">Use this game ID:
      <Button onClick={copyText}>{game}</Button>
      </h2>
      <Collapse in={copied}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setCopied(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Copied to clipboard
        </Alert>
    </Collapse>
      <Button href="form" color="primary" size="large" variant="contained" sx={{ my: 1, mx: 1.5}}>
        Start Game
        </Button>

      </Box>
    </Modal>
  </div>
  );
}
