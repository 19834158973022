import React, { useEffect } from 'react';
import {useState} from "react";

const GuessMessage = (props) => {

    let message;
    if(props.correct) {
        message = <p>&#127881; &#128003; Correct! &#127881; &#128003;</p>
    } else if (!props.correct){
      message = <p>💢 💢 Wrong 💢 💢</p>
    }

    useEffect(() => {
      const timer = setTimeout(() => {
        console.log("here");
        props.dontShow();
      }, 3000);

      // To clear or cancel a timer, you call the clearTimeout(); method,
      // passing in the timer object that you created into clearTimeout().

      return () => clearTimeout(timer);
    }, []);


    return (
      <div>
      {message}
        </div>
    );
}

export default GuessMessage
